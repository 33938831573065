/*
 * Copyright 2024 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Alert from '@amzn/meridian/alert';
import {AlertProps} from '@amzn/meridian/alert/alert';
import Box from '@amzn/meridian/box';
import Breadcrumb, {BreadcrumbGroup} from '@amzn/meridian/breadcrumb';
import Column from '@amzn/meridian/column';
import Link from '@amzn/meridian/link';
import React, {useContext, useEffect, useMemo, useReducer, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import {AppContext} from '../../../app';
import {selectSelectedRegion} from '../../../state/app/appSlice';
import {detailsReducer, KeyValueEdit} from '../../utility-views/KeyValueEdit';
import LoadingButton from '../../utility-views/LoadingButton';
import LoadingMessage from '../../utility-views/LoadingMessage';
import {EditResourceConfiguration, IPutResource} from '../Configurations/IPutResource';

const PutResourceComponent = <T, D>({configuration, inEditMode}: {
    configuration: IPutResource<T>,
    inEditMode: boolean
}) => {

    const navigate = useNavigate();
    const {webStageConfig} = useContext(AppContext);
    const selectedRegion = useSelector(selectSelectedRegion);
    const {id} = useParams();
    const [resourceIdToUse, setResourceIdToUse] = useState(inEditMode ? id : undefined);
    const [alertMessage, setAlertMessage] = useState<{
        type: AlertProps['type'],
        title?: AlertProps['title'],
        message: string,
        link?: { text: string, href?: string, onClick?: Function }

    }>(undefined);
    const [loading, setLoading] = useState(false);
    const [inError, setInError] = useState(false);
    const [resource, setResource] = useReducer(detailsReducer, configuration.initializeDefaultObject());
    const [submitted, setSubmitted] = useState(false);
    const [editConfiguration, setEditConfiguration] = useState<Record<string, EditResourceConfiguration>>(undefined);

    /**
     * Submits the resource definition.
     */
    async function onSubmit() {
        setSubmitted(true);
        try {
            const response = await configuration.putResource(webStageConfig, selectedRegion, resource);
            setAlertMessage({
                type: 'success',
                message: `Successfully ${inEditMode ? 'updated' : 'created'} ${configuration.resourceName()}: `,
                link: {
                    text: 'View Resource',
                    onClick: () => configuration.navigateViewResource(response)
                }
            });
            setSubmitted(false);
        } catch (err) {
            setAlertMessage({
                type: 'error',
                message: err.message,
                title: 'Error creating resource definition'
            });
            setSubmitted(false);
        }
    }

    /**
     * Effect which gets the list of current resource definitions.
     */
    useEffect(() => {
        (async () => {
            try {
                // Reset state
                setLoading(true);
                let config = await configuration.editResourceConfiguration(webStageConfig, selectedRegion, undefined);
                setEditConfiguration(config);
                if (!config) {
                    setAlertMessage({
                        type: 'error',
                        message: 'Edit configuration undefined',
                        title: 'Error loading edit resource configuration'
                    });
                }
            } catch (err) {
                setAlertMessage({
                    type: 'error',
                    message: err.message,
                    title: 'Error loading edit resource configuration'
                });
                console.error(err);
                setEditConfiguration(undefined);
            } finally {
                setLoading(false);
            }
        })();
    }, [selectedRegion]);

    /**
     * Effect which will retrieve the resource definition from the URL param ID.
     */
    useEffect(() => {
        if (resourceIdToUse && inEditMode) {
            (async () => {
                try {
                    // Reset type definition first
                    setLoading(true);
                    // Then load in the type definition details
                    const response = await configuration.retrieveResource(webStageConfig, selectedRegion, id);
                    setResource(response);
                    const config = await configuration.editResourceConfiguration(webStageConfig, selectedRegion, response);
                    setEditConfiguration(config);
                    Object.entries(config).forEach(([key, value]) => {
                        if (!value.editable && value.isIdentifier) {
                            setResourceIdToUse(resource[key]);
                        }
                    });

                } catch (err) {
                    setAlertMessage({
                        type: 'error',
                        message: err.message,
                        title: 'Error loading resource definition.'
                    });
                    console.error(err);
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [inEditMode]);

    /**
     * validate resource id
     * validate all details fields are not empty
     */
    const isRegistrationFormComplete = useMemo(() => {
        return Object.entries(resource).every(([key, value]) => {
            const config = editConfiguration?.[key];
            const isRequired = config?.requiredField === undefined || config?.requiredField === true;

            if (isRequired) {
                // If the field is required, it must have a non-empty value
                return !!value;
            } else {
                // If the field is not required, it can have any value (including empty)
                return true;
            }
        });
    }, [resource, editConfiguration]);


    return (
        <Column backgroundColor='subdued'
            alignmentHorizontal='start' spacingInset='500'>
            <BreadcrumbGroup>
                <Breadcrumb onClick={() => configuration.navigateTableView()}>{configuration.resourceName()}</Breadcrumb>
                <Breadcrumb>{id ?? 'Create'}</Breadcrumb>
            </BreadcrumbGroup>
            {
                alertMessage && (
                    <Alert
                        type={alertMessage.type}
                        title={alertMessage.title}
                        onClose={() => {
                            setAlertMessage(undefined);
                            setSubmitted(false);
                        }}
                    >
                        {alertMessage.message}
                        {alertMessage.link && (
                            <Link
                                onClick={() => alertMessage.link.href ? navigate(alertMessage.link.href) : alertMessage.link.onClick()}>{alertMessage.link.text}</Link>
                        )}
                    </Alert>
                )
            }
            <Box type='outline' spacingInset='400' width={'100%'}>
                {loading ?
                    (
                        <LoadingMessage size='medium' message='Loading resource...' alignmentHorizontal='start'/>
                    ) :
                    !editConfiguration ? <></> :
                        (
                            <>
                                <KeyValueEdit
                                    onError={setInError}
                                    details={resource}
                                    inEditMode={inEditMode}
                                    setIdentifier={setResourceIdToUse}
                                    setDetails={setResource}
                                    editConfiguration={editConfiguration}
                                />
                                <div style={{'paddingTop': '2%'}}>
                                    <LoadingButton
                                        data-testid={'SubmitButton'}
                                        text={inEditMode ? 'Update' : 'Submit'}
                                        isLoading={submitted}
                                        disabled={!isRegistrationFormComplete || submitted || inError}
                                        onClick={onSubmit}
                                    />
                                </div>
                            </>
                        )}
            </Box>
        </Column>
    );
};

export default PutResourceComponent;
