/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Heading from '@amzn/meridian/heading';
import React from 'react';

type PageHeaderProps = {
    headerText: string
}

const PageHeader = (props: PageHeaderProps) => {
    return (
        <Heading level={2} type='h500'>
            {props.headerText}
        </Heading>
    );
};

export default PageHeader;
