/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {
    DeleteReportConfigurationInput,
    DeleteReportConfigurationOutput,
    GetReportConfigurationInput,
    GetReportConfigurationOutput,
    ListReportConfigurationsInput,
    ListReportConfigurationsOutput,
    PutReportConfigurationInput,
    PutReportConfigurationOutput
} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/configuration';
import {ReportConfiguration} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/configuration/types';
import {
    DeleteProblemFinderOverrideInput,
    DeleteProblemFinderOverrideOutput,
    GetProblemFinderOverrideInput,
    GetProblemFinderOverrideOutput,
    ListProblemFinderOverridesInput,
    ListProblemFinderOverridesOutput,
    PutProblemFinderOverrideInput,
    PutProblemFinderOverrideOutput
} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/override';
import {ProblemFinderOverride} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/override/types';
import {
    BatchPutProblemsInput, BatchPutProblemsOutput,
    DeleteProblemInput,
    DeleteProblemOutput,
    GetProblemInput,
    GetProblemOutput,
    ListProblemsInput,
    ListProblemsOutput, UpdateProblemInput, UpdateProblemOutput
} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/problem';
import {Problem} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/problem/types';
import {
    DeleteProblemTypeDefinitionInput,
    DeleteProblemTypeDefinitionOutput,
    GetProblemTypeDefinitionInput,
    GetProblemTypeDefinitionOutput,
    ListProblemTypeDefinitionsInput,
    ListProblemTypeDefinitionsOutput,
    PutProblemTypeDefinitionInput,
    PutProblemTypeDefinitionOutput
} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/problemTypeDefinition';
import {ProblemTypeDefinition} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/problemTypeDefinition/types';
import {
    DeleteProblemFinderRegistrationInput,
    DeleteProblemFinderRegistrationOutput,
    GetProblemFinderRegistrationInput,
    GetProblemFinderRegistrationOutput,
    ListProblemFindersRegistrationInput,
    ListProblemFindersRegistrationOutput,
    PutProblemFinderRegistrationInput,
    PutProblemFinderRegistrationOutput
} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/registration';
import {ProblemFinderRegistration} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/registration/types';
import {
    DeleteWebhookRegistrationInput,
    DeleteWebhookRegistrationOutput,
    GetWebhookRegistrationInput,
    GetWebhookRegistrationOutput,
    ListWebhookRegistrationsInput,
    ListWebhookRegistrationsOutput,
    PutWebhookRegistrationInput,
    PutWebhookRegistrationOutput
} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/resources/webhooks';
import {
    DeleteProblemFinderScheduleInput,
    DeleteProblemFinderScheduleOutput,
    DeleteWebhookRegistrationSetInput,
    DeleteWebhookRegistrationSetOutput,
    GetProblemFinderScheduleInput,
    GetProblemFinderScheduleOutput,
    GetWebhookRegistrationSetInput,
    GetWebhookRegistrationSetOutput,
    ListProblemFinderSchedulesInput,
    ListProblemFinderSchedulesOutput,
    ListWebhookRegistrationSetsInput,
    ListWebhookRegistrationSetsOutput,
    ProblemFinderSchedule,
    PutProblemFinderScheduleInput,
    PutProblemFinderScheduleOutput,
    PutWebhookRegistrationSetInput,
    PutWebhookRegistrationSetOutput,
    WebhookRegistration,
    WebhookRegistrationSet
} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/scheduler';
import {API} from 'aws-amplify';

import {ApiEndpoint, DEV_STAGE_NAME, WebStageConfig} from '../config/id4-portal-config';
import {SupportedRegions} from '../state/app/appSlice';


export const getProblemFinderOverride = async (
    webStageConfig: WebStageConfig,
    region: SupportedRegions,
    input: GetProblemFinderOverrideInput
): Promise<ProblemFinderOverride> => {
    console.debug(input);
    return await sendRequestToId4Mothership<GetProblemFinderOverrideInput, GetProblemFinderOverrideOutput>(
        'GET', `/problemFinderOverrides/${input.problemFinderOverrideId}`, webStageConfig, region
    ).then(response => response.problemFinderOverride);
};

export const getWebhook = async (
    webStageConfig: WebStageConfig,
    region: SupportedRegions,
    input: GetWebhookRegistrationInput
): Promise<WebhookRegistration> => {
    console.debug(input);
    return await sendRequestToId4Mothership<GetWebhookRegistrationInput, GetWebhookRegistrationOutput>(
        'GET', `/webhookRegistrations/${input.webhookRegistrationId}`, webStageConfig, region
    ).then(response => response.webhookRegistration);
};


export const getWebhookSet = async (
    webStageConfig: WebStageConfig,
    region: SupportedRegions,
    input: GetWebhookRegistrationSetInput
): Promise<WebhookRegistrationSet> => {
    console.debug(input);
    return await sendRequestToId4Mothership<GetWebhookRegistrationSetInput, GetWebhookRegistrationSetOutput>(
        'GET', `/webhookRegistrationSets/${input.webhookRegistrationSetId}`, webStageConfig, region
    ).then(response => response.webhookRegistrationSet);
};


export const getProblem = async (
    webStageConfig: WebStageConfig,
    region: SupportedRegions,
    input: GetProblemInput
): Promise<Problem> => {
    console.debug(input);
    return await sendRequestToId4Mothership<GetProblemInput, GetProblemOutput>(
        'GET', `/problems/${input.problemId}`, webStageConfig, region
    ).then(response => response.problem);
};

export const deleteProblem = async (
    webStageConfig: WebStageConfig,
    region: SupportedRegions,
    input: DeleteProblemInput
): Promise<Problem> => {
    console.debug(input);
    return await sendRequestToId4Mothership<DeleteProblemInput, DeleteProblemOutput>(
        'DELETE', `/problems/${input.problemId}`, webStageConfig, region
    ).then(response => response.problem);
};

export const getProblemFinderRegistration = async (
    webStageConfig: WebStageConfig,
    region: SupportedRegions,
    input: GetProblemFinderRegistrationInput
): Promise<ProblemFinderRegistration> => {
    console.debug(input);
    return await sendRequestToId4Mothership<GetProblemFinderRegistrationInput, GetProblemFinderRegistrationOutput>(
        'GET', `/problemFinders/${input.problemFinderId}`, webStageConfig, region
    ).then(response => response.registration);
};

export const putProblemFinderRegistration = async (
    webStageConfig: WebStageConfig,
    region: SupportedRegions,
    input: PutProblemFinderRegistrationInput
): Promise<PutProblemFinderRegistrationOutput> => {
    console.debug(input);
    return await sendRequestToId4Mothership<PutProblemFinderRegistrationInput, PutProblemFinderRegistrationOutput>(
        'PUT', `/problemFinders/${input.problemFinderId}`, webStageConfig, region, input
    );
};

export const putProblemFinderOverride = async (
    webStageConfig: WebStageConfig,
    region: SupportedRegions,
    input: PutProblemFinderOverrideInput
): Promise<PutProblemFinderOverrideOutput> => {
    console.debug(input);
    return await sendRequestToId4Mothership<PutProblemFinderOverrideInput, PutProblemFinderOverrideOutput>(
        'PUT', `/problemFinderOverrides/${input.problemFinderOverrideId}`, webStageConfig, region, input
    );
};

export const putWebhook = async (
    webStageConfig: WebStageConfig,
    region: SupportedRegions,
    input: PutWebhookRegistrationInput
): Promise<PutWebhookRegistrationOutput> => {
    console.debug(input);
    return await sendRequestToId4Mothership<PutWebhookRegistrationInput, PutWebhookRegistrationOutput>(
        'PUT', `/webhookRegistrations/${input.webhookRegistrationId}`, webStageConfig, region, input
    );
};


export const putWebhookSet = async (
    webStageConfig: WebStageConfig,
    region: SupportedRegions,
    input: PutWebhookRegistrationSetInput
): Promise<PutWebhookRegistrationSetOutput> => {
    console.debug(input);
    return await sendRequestToId4Mothership<PutWebhookRegistrationSetInput, PutWebhookRegistrationSetOutput>(
        'PUT', `/webhookRegistrationSets/${input.webhookRegistrationSetId}`, webStageConfig, region, input
    );
};


export const listWebhooks = async (webStageConfig: WebStageConfig, region: SupportedRegions): Promise<WebhookRegistration[]> => {
    const webhooks: WebhookRegistration[] = [];
    let nextToken: string = undefined;
    do {
        const response = await sendRequestToId4Mothership<ListWebhookRegistrationsInput, ListWebhookRegistrationsOutput>(
            'GET', '/webhookRegistrations', webStageConfig, region, nextToken ? {nextToken: nextToken} : undefined
        );
        nextToken = response.nextToken;
        if (response.webhookRegistrations) {
            webhooks.push(...response.webhookRegistrations);
        }
    } while (nextToken !== undefined);
    return webhooks;
};

export const listWebhookSets = async (webStageConfig: WebStageConfig, region: SupportedRegions): Promise<WebhookRegistrationSet[]> => {
    const webhooks: WebhookRegistrationSet[] = [];
    let nextToken: string = undefined;
    do {
        const response = await sendRequestToId4Mothership<ListWebhookRegistrationSetsInput, ListWebhookRegistrationSetsOutput>(
            'GET', '/webhookRegistrationSets', webStageConfig, region, nextToken ? {nextToken: nextToken} : undefined
        );
        nextToken = response.nextToken;
        if (response.webhookRegistrationSets) {
            webhooks.push(...response.webhookRegistrationSets);
        }
    } while (nextToken !== undefined);
    return webhooks;
};


export const listProblemFinderOverrides = async (webStageConfig: WebStageConfig, region: SupportedRegions): Promise<ProblemFinderOverride[]> => {
    const overrides: ProblemFinderOverride[] = [];
    let nextToken: string = undefined;
    do {
        const response = await sendRequestToId4Mothership<ListProblemFinderOverridesInput, ListProblemFinderOverridesOutput>(
            'GET', '/problemFinderOverrides', webStageConfig, region, nextToken ? {nextToken: nextToken} : undefined
        );
        nextToken = response.nextToken;
        if (response.problemFinderOverrides) {
            overrides.push(...response.problemFinderOverrides);
        }
    } while (nextToken !== undefined);
    return overrides;
};


export const listProblemFinderRegistrations = async (webStageConfig: WebStageConfig, region: SupportedRegions): Promise<ProblemFinderRegistration[]> => {
    const registrations: ProblemFinderRegistration[] = [];
    let nextToken: string = undefined;
    do {
        const response = await sendRequestToId4Mothership<ListProblemFindersRegistrationInput, ListProblemFindersRegistrationOutput>(
            'GET', '/problemFinders', webStageConfig, region, nextToken ? {nextToken: nextToken} : undefined
        );
        nextToken = response.nextToken;
        registrations.push(...response.registrations);
    } while (nextToken !== undefined);
    return registrations;
};


export const deleteWebhook = async (
    webStageConfig: WebStageConfig,
    region: SupportedRegions,
    input: DeleteWebhookRegistrationInput
): Promise<DeleteWebhookRegistrationOutput> => {
    console.debug(input);
    return await sendRequestToId4Mothership<DeleteWebhookRegistrationInput, DeleteWebhookRegistrationOutput>(
        'DELETE', `/webhookRegistrations/${input.webhookRegistrationId}`, webStageConfig, region
    );
};

export const deleteWebhookSet = async (
    webStageConfig: WebStageConfig,
    region: SupportedRegions,
    input: DeleteWebhookRegistrationSetInput
): Promise<DeleteWebhookRegistrationSetOutput> => {
    console.debug(input);
    return await sendRequestToId4Mothership<DeleteWebhookRegistrationSetInput, DeleteWebhookRegistrationSetOutput>(
        'DELETE', `/webhookRegistrationSets/${input.webhookRegistrationSetId}`, webStageConfig, region
    );
};


export const deleteProblemFinderRegistration = async (
    webStageConfig: WebStageConfig,
    region: SupportedRegions,
    input: DeleteProblemFinderRegistrationInput
): Promise<DeleteProblemFinderRegistrationOutput> => {
    console.debug(input);
    return await sendRequestToId4Mothership<DeleteProblemFinderRegistrationInput, DeleteProblemFinderRegistrationOutput>(
        'DELETE', `/problemFinders/${input.problemFinderId}`, webStageConfig, region
    );
};

export const deleteReportConfiguration = async (
    webStageConfig: WebStageConfig,
    region: SupportedRegions,
    input: DeleteReportConfigurationInput
): Promise<DeleteReportConfigurationOutput> => {
    console.debug(input);
    return await sendRequestToId4Mothership<DeleteReportConfigurationInput, DeleteReportConfigurationOutput>(
        'DELETE', `/reportConfigurations/${input.reportConfigurationId}`, webStageConfig, region
    );
};

export const deleteProblemFinderOverride = async (
    webStageConfig: WebStageConfig,
    region: SupportedRegions,
    input: DeleteProblemFinderOverrideInput
): Promise<DeleteProblemFinderOverrideOutput> => {
    console.debug(input);
    return await sendRequestToId4Mothership<DeleteProblemFinderOverrideInput, DeleteProblemFinderOverrideOutput>(
        'DELETE', `/problemFinderOverrides/${input.problemFinderOverrideId}`, webStageConfig, region
    );
};

export const deleteProblemTypeDefinition = async (
    webStageConfig: WebStageConfig,
    region: SupportedRegions,
    input: DeleteProblemTypeDefinitionInput
): Promise<DeleteProblemTypeDefinitionOutput> => {
    console.debug(input);
    return await sendRequestToId4Mothership<DeleteProblemTypeDefinitionInput, DeleteProblemTypeDefinitionOutput>(
        'DELETE', `/problemTypeDefinitions/${input.problemTypeId}`, webStageConfig, region
    );
};

export const getProblemFinderSchedule = async (
    webStageConfig: WebStageConfig,
    region: SupportedRegions,
    input: GetProblemFinderScheduleInput
): Promise<ProblemFinderSchedule> => {
    console.debug(input);
    return await sendRequestToId4Mothership<GetProblemFinderScheduleInput, GetProblemFinderScheduleOutput>(
        'GET', `/problemFinderSchedules/${input.problemFinderScheduleId}`, webStageConfig, region
    ).then(response => response.problemFinderSchedule);
};

export const isProblemFinderEnabled = async (
    webStageConfig: WebStageConfig,
    region: SupportedRegions,
    problemFinderId: string
): Promise<boolean> => {
    // TODO: There is for sure a better way of doing this.
    let schedules = await listProblemFinderSchedule(webStageConfig, region);
    let schedule = schedules.filter(schedule => schedule.problemFinderId === problemFinderId);
    if (schedule.length) {
        return schedule[0].enabled;
    } else {
        return false;
    }
};

export const getProblemFinderScheduleForProblemFinderId = async (
    webStageConfig: WebStageConfig,
    region: SupportedRegions,
    problemFinderId: string
): Promise<ProblemFinderSchedule> => {
    // TODO: There is for sure a better way of doing this.
    let schedules = await listProblemFinderSchedule(webStageConfig, region);
    let schedule = schedules.filter(schedule => schedule.problemFinderId === problemFinderId);
    if (schedule.length) {
        return schedule[0];
    } else {
        return undefined;
    }
};

export const putProblemFinderSchedule = async (
    webStageConfig: WebStageConfig,
    region: SupportedRegions,
    input: PutProblemFinderScheduleInput
): Promise<PutProblemFinderScheduleOutput> => {
    console.debug(input);
    return await sendRequestToId4Mothership<PutProblemFinderScheduleInput, PutProblemFinderScheduleOutput>(
        'PUT', `/problemFinderSchedules/${input.problemFinderScheduleId}`, webStageConfig, region, input
    );
};


export const deleteProblemFinderSchedule = async (
    webStageConfig: WebStageConfig,
    region: SupportedRegions,
    input: DeleteProblemFinderScheduleInput
): Promise<DeleteProblemFinderScheduleOutput> => {
    console.debug(input);
    return await sendRequestToId4Mothership<DeleteProblemFinderScheduleInput, DeleteProblemFinderScheduleOutput>(
        'DELETE', `/problemFinderSchedules/${input.problemFinderScheduleId}`, webStageConfig, region
    );
};


export const listProblems = async (
    webStageConfig: WebStageConfig,
    region: SupportedRegions,
    input: ListProblemsInput
): Promise<Problem[]> => {
    const problems: Problem[] = [];
    let nextToken: string = undefined;
    let inputPayload: Record<string, any> = {};
    Object.entries(input).forEach(([key, value]) => {
        if (value) {
            inputPayload[key] = value;
        }
    });
    do {
        const response = await sendRequestToId4Mothership<ListProblemsInput, ListProblemsOutput>(
            'GET', `/problems`, webStageConfig, region, nextToken ? {...inputPayload, nextToken: nextToken} : {...inputPayload}
        );
        nextToken = response.nextToken;
        problems.push(...response.problems);
    } while (nextToken !== undefined);
    return problems;
};

export const listProblemFinderSchedule = async (
    webStageConfig: WebStageConfig,
    region: SupportedRegions
): Promise<ProblemFinderSchedule[]> => {
    const schedules: ProblemFinderSchedule[] = [];
    let nextToken: string = undefined;
    do {
        const response = await sendRequestToId4Mothership<ListProblemFinderSchedulesInput, ListProblemFinderSchedulesOutput>(
            'GET', `/problemFinderSchedules`, webStageConfig, region, nextToken ? {nextToken: nextToken} : undefined
        );
        nextToken = response.nextToken;
        schedules.push(...response.problemFinderSchedules);
    } while (nextToken !== undefined);
    return schedules;
};

export const getReportConfiguration = async (
    webStageConfig: WebStageConfig,
    region: SupportedRegions,
    input: GetReportConfigurationInput
): Promise<ReportConfiguration> => {
    console.debug(input);
    return await sendRequestToId4Mothership<GetReportConfigurationInput, GetReportConfigurationOutput>(
        'GET', `/reportConfigurations/${input.reportConfigurationId}`, webStageConfig, region
    ).then(response => response.reportConfiguration);
};

export const putReportConfiguration = async (
    webStageConfig: WebStageConfig,
    region: SupportedRegions,
    input: PutReportConfigurationInput
): Promise<PutReportConfigurationOutput> => {
    console.debug(input);
    return await sendRequestToId4Mothership<PutReportConfigurationInput, PutReportConfigurationOutput>(
        'PUT', `/reportConfigurations/${input.reportConfigurationId}`, webStageConfig, region, input
    );
};

export const listReportConfigurations = async (webStageConfig: WebStageConfig, region: SupportedRegions): Promise<ReportConfiguration[]> => {
    const configurations: ReportConfiguration[] = [];
    let nextToken: string = undefined;
    do {
        const response = await sendRequestToId4Mothership<ListReportConfigurationsInput, ListReportConfigurationsOutput>(
            'GET', '/reportConfigurations', webStageConfig, region, nextToken ? {nextToken: nextToken} : undefined
        );
        nextToken = response.nextToken;
        configurations.push(...response.reportConfigurations);
    } while (nextToken !== undefined);
    return configurations;
};

export const getProblemTypeDefinition = async (
    webStageConfig: WebStageConfig,
    region: SupportedRegions,
    input: GetProblemTypeDefinitionInput
): Promise<ProblemTypeDefinition> => {
    console.debug(input);
    return await sendRequestToId4Mothership<GetProblemTypeDefinitionInput, GetProblemTypeDefinitionOutput>(
        'GET', `/problemTypeDefinitions/${input.problemTypeId}`, webStageConfig, region
    ).then(response => response.problemTypeDefinition);
};

export const putProblemTypeDefinition = async (
    webStageConfig: WebStageConfig,
    region: SupportedRegions,
    input: PutProblemTypeDefinitionInput
): Promise<PutProblemTypeDefinitionOutput> => {
    console.debug(input);
    return await sendRequestToId4Mothership<PutProblemTypeDefinitionInput, PutProblemTypeDefinitionOutput>(
        'PUT', `/problemTypeDefinitions/${input.problemTypeId}`, webStageConfig, region, input
    );
};

export const batchPutProblems = async (
    webStageConfig: WebStageConfig,
    region: SupportedRegions,
    input: BatchPutProblemsInput
): Promise<BatchPutProblemsOutput> => {
    return await sendRequestToId4Mothership<BatchPutProblemsInput, BatchPutProblemsOutput>(
        'POST', `/batchPutProblems/`, webStageConfig, region, input
    );
};

export const listProblemTypeDefinitions = async (webStageConfig: WebStageConfig, region: SupportedRegions): Promise<ProblemTypeDefinition[]> => {
    const types: ProblemTypeDefinition[] = [];
    let nextToken: string = undefined;
    do {
        const response = await sendRequestToId4Mothership<ListProblemTypeDefinitionsInput, ListProblemTypeDefinitionsOutput>(
            'GET', '/problemTypeDefinitions', webStageConfig, region, nextToken ? {nextToken: nextToken} : undefined
        );
        nextToken = response.nextToken;
        types.push(...response.problemTypeDefinitions);
    } while (nextToken !== undefined);
    return types;
};

const sendRequestToId4Mothership = async <I, O>(verb: 'GET' | 'PUT' | 'DELETE' | 'PATCH' | 'POST', path: string, webStageConfig: WebStageConfig, region: SupportedRegions, payload?: I): Promise<O> => {
    const apiName = getId4MothershipEndpoint(webStageConfig, region).name;
    console.debug(`Using ${apiName} API`);
    switch (verb) {
        case 'GET': {
            return await API.get(apiName, path, payload ? {
                queryStringParameters: {
                    ...payload
                }
            } : {});
        }
        case 'POST': {
                return await API.post(apiName, path, {
                    body: payload ?? {}
                });
            }
        case 'PUT': {
            return await API.put(apiName, path, {
                body: payload ?? {}
            });
        }
        case 'PATCH': {
            return await API.patch(apiName, path, {
                body: payload ?? {}
            });
        }
        case 'DELETE': {
            return await API.del(apiName, path, payload ? {
                queryStringParameters: {
                    ...payload
                }
            } : {});
        }
    }
};

const getId4MothershipEndpoint = (webStageConfig: WebStageConfig, region: SupportedRegions): ApiEndpoint => {
    // Dev, alpha, and beta only have one endpoint
    if ([DEV_STAGE_NAME, 'Alpha', 'Beta'].includes(webStageConfig.name)) {
        return webStageConfig.mothershipApiEndpoints[0];
    } else {
        // For prod, there are three regional endpoints so use the selected region when determining which API to call
        return webStageConfig.mothershipApiEndpoints.find(ep => ep.regionValue === region);
    }
};
