/*
 * Copyright 2024 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {WebStageConfig} from '../../../config/id4-portal-config';
import {SupportedRegions} from '../../../state/app/appSlice';
import {IDeleteResource} from './IDeleteResource';

/**
 * The list resource component types.
 */
export enum ListResourceComponentType {
    TEXT,
    TIME,
    BADGE,
    TAG,
    CTI
}

/**
 * The list resource component types.
 */
export enum QueryFieldComponentType {
    TEXT,
    SELECT,
    DATETIME,
}

/**
 * The list resource configuration.
 */
export class ListResourceConfiguration<T> {
    componentType: ListResourceComponentType;
    displayName?: string;
    maxValueLength?: number;
    sortable?: boolean;
    valueRetrieve?: (resource: T) => any;
    styleRetrieve?: (resource: T) => any;
    onClick?: (value: string) => void;
}

/**
 * QueryFieldConfiguration
 */
export class QueryFieldConfiguration {
    fieldName: string;
    componentType: QueryFieldComponentType;
    required: boolean;
    label: string;
    valueRetrieve?: () => string[];
}

/**
 * Manage action configuration
 */
export class ManageActionConfiguration<T> {
    actionName: string;
    enabled: (resource: T[]) => boolean;
    action: (resource: T[]) => Promise<boolean>;
}


/**
 * The table resource interface.
 */
export interface ITableResource<T> extends Partial<IDeleteResource<T>> {

    /**
     * The name of the resource.
     */
    resourceName(): string

    /**
     * Get the ID of the resource.
     * @param resource to get ID for.
     */
    getId(resource: T): string;

    /**
     * Navigate to table view.
     */
    navigateTableView(): void;

    /**
     * Navigate to view resource if it has a view.
     * @param resource to view.
     */
    navigateViewResource?(resource: T): void;

    /**
     * Navigate to create resource. If it has a way to be created via portal.
     */
    navigateCreateResource?(): void,

    /**
     * Navigate to edit resource. If it is editable in web app.
     * @param resource to edit.
     */
    navigateEditResource?(resource: T): void;

    /**
     * Puts the given resource.
     * @param webStageConfig the web stage config.
     * @param region the region.
     * @param resource the resource to put.
     */
    putResource(webStageConfig: WebStageConfig, region: SupportedRegions, resource: T): Promise<T>;


    /**
     * Initialize a default version of the object.
     */
    initializeDefaultObject(): T

    /**
     * Retrieve all resources of this type.
     * @param webStageConfig the web stage config.
     * @param region the region to pull from.
     * @param query optional query.
     */
    retrieveResources(webStageConfig: WebStageConfig, region: SupportedRegions, query?: Record<string, string>): Promise<T[]>

    /**
     * The list resource configuration. Defines how each resource is displayed in a row.
     * @param webStageConfig the web stage config.
     * @param selectedRegion the region.
     */
    listResourceConfiguration(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions): Promise<Record<string, ListResourceConfiguration<T>>>


    /**
     * Whether rendering the results requires the user to provide a query prior to display.
     */
    queryFields?(webStageConfig: WebStageConfig, region: SupportedRegions): Promise<QueryFieldConfiguration[]>

    /**
     * Manage resource actions.
     * @param webStageConfig the web stage config.
     * @param region the region.
     */
    manageResourceActions?(webStageConfig: WebStageConfig, region: SupportedRegions): ManageActionConfiguration<T>[]

}
