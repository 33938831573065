/*
 * Copyright 2024 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Alert from '@amzn/meridian/alert';
import {AlertProps} from '@amzn/meridian/alert/alert';
import Badge from '@amzn/meridian/badge';
import Box from '@amzn/meridian/box';
import Button from '@amzn/meridian/button';
import Checkbox from '@amzn/meridian/checkbox';
import Column from '@amzn/meridian/column';
import DatePicker from '@amzn/meridian/date-picker/date-picker';
import Expander from '@amzn/meridian/expander';
import FileInput from '@amzn/meridian/file-input';
import Icon from '@amzn/meridian/icon';
import Input from '@amzn/meridian/input';
import InputGroup from '@amzn/meridian/input-group';
import Link from '@amzn/meridian/link';
import Modal from '@amzn/meridian/modal';
import Pagination from '@amzn/meridian/pagination';
import Row from '@amzn/meridian/row';
import SearchField from '@amzn/meridian/search-field';
import Select, {SelectOption} from '@amzn/meridian/select';
import Table, {TableActionBar, TableActionBarOverlay, TableCell, TableRow,} from '@amzn/meridian/table';
import {TableSortDirection} from '@amzn/meridian/table/table';
import Tag from '@amzn/meridian/tag';
import Text from '@amzn/meridian/text';
import TimePicker from '@amzn/meridian/time-picker/time-picker';
import refreshTokens from '@amzn/meridian-tokens/base/icon/refresh';
import {truncate} from 'lodash';
import moment from 'moment-timezone';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import {AppContext} from '../../../app';
import {selectSelectedRegion} from '../../../state/app/appSlice';
import {formatTimestamp} from '../../../utility/format-helper';
import LoadingMessage from '../../utility-views/LoadingMessage';
import PageHeader from '../../utility-views/PageHeader';
import {
    ITableResource,
    ListResourceComponentType,
    ListResourceConfiguration,
    QueryFieldComponentType,
    QueryFieldConfiguration
} from '../Configurations/ITableResource';

const TableResourceComponent = <T, D>({configuration}: { configuration: ITableResource<T> }) => {
    const ITEMS_PER_PAGE = 25;

    const {webStageConfig} = React.useContext(AppContext);
    const selectedRegion = useSelector(selectSelectedRegion);

    const [alertMessage, setAlertMessage] = useState<{
        type: AlertProps['type'],
        title?: AlertProps['title'],
        message: string
    }>(undefined);

    const [selected, setSelected] = useState<T[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [itemsPerPage, setItemsPerPage] = useState<number>(ITEMS_PER_PAGE);
    const checkedCount = Object.values(selected).filter(Boolean).length;

    const [deleteConfirmationOpen, setDeleteConfirmationModalOpen] = useState<boolean>(false);
    const [resourceActionsModal, setResourceActionsModal] = useState<boolean>(false);

    const onCloseActionBar = () => {
        setSelected([]);
    };

    const [listResourceConfiguration, setListResourceConfiguration] = useState<Record<string, ListResourceConfiguration<T>>>({});
    const [filteredResources, setFilteredResources] = useState<T[]>([]);
    const [loadingResources, setLoadingResources] = useState<boolean>(true);
    const [resourcesMap, setResourcesMap] = useState<Record<string, T>>({});
    const [unmaskedResources, setUnmaskedResources] = useState<Record<string, T>>({});
    const [queryFields, setQueryFields] = useState<QueryFieldConfiguration[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
    const lastVisibleIndex = firstVisibleIndex + itemsPerPage;
    const [sortColumn, setSortColumn] = useState<string>(undefined);
    const [sortDirection, setSortDirection] = useState<TableSortDirection>('ascending');
    const [query, setQuery] = useState<Record<string, string>>({});
    const [queryDates, setQueryDates] = useState<Record<string, string>>({});
    const [queryTimes, setQueryTimes] = useState<Record<string, string>>({});
    const [queryTextInputs, setQueryTextInputs] = useState<Record<string, string>>({});
    const [querySelectInputs, setQuerySelectInputs] = useState<Record<string, string>>({});
    const [queryTimezones, setQueryTimezones] = useState<Record<string, string>>({});
    const [queryBoxOpen, setQueryBoxOpen] = useState(true);
    const numberOfPages = Math.ceil(Object.values(resourcesMap).length / itemsPerPage);

    /**
     * When file is selected to upload, parses file and submits resources.
     * @param acceptedFiles files selected.
     */
    const onResourceUpload = async (acceptedFiles: File[]) => {
        for (const file of acceptedFiles) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const contents = reader.result as string;
                const jsonData = JSON.parse(contents) as T[];
                if (!isArrayOfT(jsonData)) {
                    setAlertMessage({
                        type: 'error',
                        message: 'Ensure the file you uploaded contains a JSON array of the proper resource type.',
                        title: 'Invalid JSON for Resource'
                    });
                } else {
                    for (const resource of jsonData) {
                        try {
                            await configuration.putResource(webStageConfig, selectedRegion, resource);
                        } catch (e) {
                            console.log(e);
                            setAlertMessage({
                                type: 'error',
                                message: 'Ensure the resource you are uploading has all the required fields and has any' +
                                    ' dependent resources properly registered.',
                                title: 'Failed to Upload Resource(s)'
                            });
                        }
                    }
                }
                await fetchResources();
            };
            await reader.readAsText(file);
        }
    };

    const onSort = ({sortColumn, sortDirection}: { sortColumn: string, sortDirection: TableSortDirection }) => {
        setSortDirection(sortDirection);
        setSortColumn(sortColumn);
    };

    /**
     * Method to delete a set of resources
     * @param resourcesToDelete the set of resources to delete.
     */
    const deleteResources = async (resourcesToDelete: T[]) => {
        for (const resource of resourcesToDelete) {
            await configuration.deleteResource(webStageConfig, selectedRegion, resource)
                .catch((err: any) => {
                    setAlertMessage({
                        type: 'error',
                        message: err.message,
                        title: 'Error deleting resource(s)'
                    });
                    console.error(err);
                });
        }

        setAlertMessage({
            type: 'success',
            message: '',
            title: 'Resource Deleted'
        });

        // Delete the resource from the list
        setFilteredResources(prevResources => prevResources
            .filter(resource => !resourcesToDelete.includes(resource)));

        setSelected([]);

    };


    /**
     * Method used to fetch resources.
     */
    const fetchResources = async () => {
        try {
            let queryFields = configuration.queryFields && await configuration.queryFields(webStageConfig, selectedRegion);
            setQueryFields(queryFields);
            if (hasQuery(queryFields)) {
                // Reset the configuration state
                setLoadingResources(true);
                setResourcesMap({});
                setFilteredResources([]);
                // Then retrieve the list of report resources
                let currentResources = await configuration.retrieveResources(webStageConfig, selectedRegion, query);
                let viewConfig = await configuration.listResourceConfiguration(webStageConfig, selectedRegion);

                let unmaskedResources: Record<string, T> = {};
                currentResources.forEach(r => {
                    unmaskedResources[configuration.getId(r)] = r;
                });
                setUnmaskedResources(unmaskedResources);
                setListResourceConfiguration(viewConfig);
                for (const resource of currentResources) {
                    for (const key of Object.keys(viewConfig)) {
                        let config = viewConfig[key];
                        let newValue = config.valueRetrieve ?
                            await config.valueRetrieve(resource) : resource[key as keyof typeof resource];

                        setResourcesMap(prevState => (
                            {
                                ...prevState,
                                [configuration.getId(resource)]: {
                                    ...prevState[configuration.getId(resource)],
                                    [key]: newValue
                                }
                            }));
                    }
                }
            }
        } catch (err) {
            setAlertMessage({
                type: 'error',
                message: err.message,
                title: 'Error loading known resources.'
            });
            console.error(err);
        }

        setLoadingResources(false);
    };

    /**
     * Whether we have necessary query fields.
     * @param queryFieldSet the query field set.
     * @returns {boolean} whether it has a query.
     */
    const hasQuery = (queryFieldSet: QueryFieldConfiguration[]) => {
        if (queryFieldSet !== undefined) {
            let fieldNames = queryFieldSet.filter(qf => qf.required).map(qf => qf.fieldName);
            let queryFields = new Set(Object.keys(query));
            return !!resourcesMap && fieldNames.every((item) => queryFields.has(item));
        } else {
            return true;
        }
    };


    /**
     * Effect which gets a list of resources.
     */
    React.useEffect(() => {
        fetchResources();
    }, [selectedRegion, query]);

    /**
     *
     */
    function setFilteredResourcesForInputValue() {
        if (inputValue) {
            setFilteredResources(Object.values(resourcesMap).filter(resource => {
                return Object.values(resource).map(v => !v ? '' : v.toString().toLowerCase())
                    .join('').includes(inputValue.toLowerCase());
            }));
        } else {
            setFilteredResources(Object.values(resourcesMap));
        }
    }

    /**
     * Effect which will use the input value to filter the problem finder IDs.
     */
    React.useEffect(() => {
        setFilteredResourcesForInputValue();
    }, [resourcesMap, itemsPerPage, inputValue, selectedRegion, query]);


    /**
     * Gets the filtered and paginated set of resources.
     */
    const filteredAndPaginatedResources = React.useMemo(() => {
        return filteredResources
            .sort((a: any, b: any) => {
                if (a[sortColumn] < b[sortColumn]) {
                    return sortDirection === 'ascending' ? -1 : 1;
                }
                if (a[sortColumn] > b[sortColumn]) {
                    return sortDirection === 'ascending' ? 1 : -1;
                }
                return 0;
            })
            .slice(firstVisibleIndex, lastVisibleIndex);
    }, [currentPage, filteredResources, sortDirection, sortColumn, selectedRegion]);

    /**
     * Checks to see if the given data is an array of element T.
     * @param data the data to validate.
     * @returns {boolean} whether it's valid.
     */
    const isArrayOfT = (data: any): data is T[] => {
        return Array.isArray(data) && data.every(item => typeof item === 'object' && item !== null
            && Object.keys(configuration.initializeDefaultObject()).every(field => Object.keys(item).includes(field)));
    };

    /**
     * Exports the selected resources to a json file.
     */
    const handleExport = async () => {
        let resources = await configuration.retrieveResources(webStageConfig, selectedRegion, query);
        let resourcesIdMap: Record<string, T> = resources.reduce((acc, obj) => ({...acc, [configuration.getId(obj)]: obj}), {});
        let selectedIdSet = selected.map(s => configuration.getId(s));
        let resourcesToExport: T[] = selectedIdSet.map(id => resourcesIdMap[id]);
        const jsonData = JSON.stringify(resourcesToExport);
        const blob = new Blob([jsonData], {type: 'application/json'});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = ''; // Empty string for default filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    /**
     * Build the query.
     * @returns {Record<string, string>} the query.
     */
    function buildQuery(): Record<string, string> {

        /**
         * Converts datetime to ms
         * @param dateString the date string.
         * @param timeString the time string.
         * @param timeZone the time zone.
         * @returns {number} the ms
         */
        function dateTimeWithTimeZoneToMs(dateString: string, timeString: string, timeZone: string): number {
            const dateTimeString = `${dateString}T${timeString}`;

            if (!moment.tz.zone(timeZone)) {
                throw new Error(`Invalid time zone: ${timeZone}`);
            }
            const dateTimeInZone = moment.tz(dateTimeString, timeZone);
            if (!dateTimeInZone.isValid()) {
                throw new Error(`Invalid date or time: ${dateTimeString}`);
            }

            return dateTimeInZone.valueOf();
        }

        let query = {
            ...queryTextInputs,
            ...querySelectInputs,
        };

        queryFields.forEach(config => {
            if (config.componentType === QueryFieldComponentType.DATETIME) {
                let time = queryTimes[config.fieldName];
                let date = queryDates[config.fieldName];
                let timeZone = queryTimezones[config.fieldName] ?? 'UTC';
                if (time && date && timeZone) {
                    query[config.fieldName] = dateTimeWithTimeZoneToMs(date, time, timeZone).toString();
                }
            }
        });

        console.log(query);
        return query;
    }

    /**
     * Determines how to display the resource.
     *
     * @param attribute to display.
     * @param resource the display.
     *
     * @returns {React.ReactElement} the element.
     */
    function displayAttribute(attribute: string, resource: T): React.ReactElement {
        if (!(attribute in listResourceConfiguration)) {
            return <></>;
        }

        let config: ListResourceConfiguration<T> = listResourceConfiguration[attribute];
        let value: any = resourcesMap[configuration.getId(resource)][attribute as keyof typeof resource];
        let valueElement: React.ReactElement = (<></>);
        switch (config.componentType) {
            case ListResourceComponentType.TEXT:
                if (!value) {
                    return <Text>N/A</Text>;
                }
                valueElement = (
                    <Text>
                        {
                            config.maxValueLength ?
                                truncate(value.toString(), {length: config.maxValueLength})
                                : value.toString()}
                    </Text>

                );
                break;
            case ListResourceComponentType.TAG:
                if (!value) {
                    return <Tag type={'neutral'}>N/A</Tag>;
                }
                valueElement = (<Tag type={config.styleRetrieve ? config.styleRetrieve(resource) : 'theme'}>
                    {config.maxValueLength ?
                        truncate(value.toString(), {length: config.maxValueLength}).toUpperCase()
                        : value.toString().toUpperCase()}
                </Tag>
                );
                break;
            case ListResourceComponentType.BADGE:
                if (!value) {
                    return <Tag type={'neutral'}>N/A</Tag>;
                }
                valueElement = <Badge value={value}/>;
                break;
            case ListResourceComponentType.CTI:
                if (!value) {
                    return <Text>N/A</Text>;
                }
                valueElement = (
                    <Row>
                        <Text>
                            {value.category}/{value.type}/{value.item}
                        </Text>
                    </Row>
                );
                break;
            case ListResourceComponentType.TIME:
                if (!value) {
                    return <Text>N/A</Text>;
                }
                valueElement = (<Text>
                    {formatTimestamp(value, 'en-US')}
                </Text>
                );
                break;
        }

        return (
            <>
                {config.onClick ?
                    <Link type={'secondary'} onClick={() => config.onClick(value)}>{valueElement}</Link>
                    : valueElement
                }
            </>
        );
    }

    /**
     * Renders the query field.
     * @param field the field to render.
     * @returns {React.Fragment} the rendered field.
     */
    function renderQueryField(field: QueryFieldConfiguration) {
        let renderedQueryField = undefined;
        switch (field.componentType) {
            case QueryFieldComponentType.SELECT:
                renderedQueryField = <Select
                    size={'small'}
                    value={querySelectInputs[field.fieldName]}
                    label={field.label}
                    error={!querySelectInputs[field.fieldName] && field.required}
                    onChange={(newValue) => setQuerySelectInputs({...querySelectInputs, [field.fieldName]: newValue})}
                >
                    {field.valueRetrieve().map(option => (
                        <SelectOption label={option} key={option} value={option}/>
                    ))}
                    <></>
                </Select>;
                break;
            case QueryFieldComponentType.TEXT:
                renderedQueryField = (
                    <Input
                        error={!queryTextInputs[field.fieldName] && field.required}
                        size={'small'}
                        value={queryTextInputs[field.fieldName]}
                        onChange={(newValue) => setQueryTextInputs({...queryTextInputs, [field.fieldName]: newValue})}
                        label={field.label}/>
                );
                break;
            case QueryFieldComponentType.DATETIME:
                renderedQueryField = (
                    <Column>
                        <InputGroup size='small'>
                            <DatePicker
                                error={
                                    (!queryDates[field.fieldName] && field.required)
                                    || (queryTimes[field.fieldName] && !queryDates[field.fieldName])
                                    || (queryTimezones[field.fieldName] && !queryDates[field.fieldName])
                                }
                                value={queryDates[field.fieldName]}
                                onChange={(newValue) => setQueryDates({
                                    ...queryDates,
                                    [field.fieldName]: newValue
                                })}
                                label={field.label + ' Date'}
                            />
                            <TimePicker
                                error={
                                    (!queryTimes[field.fieldName] && field.required)
                                    || (queryDates[field.fieldName] && !queryTimes[field.fieldName])
                                    || (queryTimezones[field.fieldName] && !queryTimes[field.fieldName])
                                }
                                label={field.label + ' Time'}
                                value={queryTimes[field.fieldName]}
                                onChange={(newValue) => setQueryTimes({
                                    ...queryTimes,
                                    [field.fieldName]: newValue
                                })}
                            />
                            <Select
                                width={200}
                                error={
                                    (!queryTimezones[field.fieldName] && field.required)
                                    || (queryDates[field.fieldName] && !queryTimezones[field.fieldName])
                                    || (queryTimes[field.fieldName] && !queryTimezones[field.fieldName])
                                }
                                label={field.label + ' Timezone'}
                                value={queryTimezones[field.fieldName]}
                                onChange={(newValue) => setQueryTimezones({
                                    ...queryTimezones,
                                    [field.fieldName]: newValue
                                })}
                                valueLabel={values => {
                                    if (values.length === 1) {
                                        return `${values[0].value}`;
                                    } else {
                                        return '';
                                    }
                                }}
                            >
                                {
                                    moment.tz.names().map(tz => {
                                        return (<SelectOption key={tz} label={tz} value={tz}/>);
                                    })
                                }
                            </Select>
                        </InputGroup>

                    </Column>

                );
                break;
            default:
                renderedQueryField = (<></>);
                break;
        }
        return renderedQueryField;
    }


    /**
     * Are all required fields provided.
     * @param fields to check.
     * @returns {boolean} whether it's ready.
     */
    function isQueryReady(fields: QueryFieldConfiguration[]): boolean {
        return fields.every(field => {
            switch (field.componentType) {
                case QueryFieldComponentType.SELECT:
                    return field.required ? !!querySelectInputs[field.fieldName] : true;
                case QueryFieldComponentType.TEXT:
                    return field.required ? !!queryTextInputs[field.fieldName] : true;
                case QueryFieldComponentType.DATETIME:
                    return field.required ? !!queryTimes[field.fieldName]
                        && !!queryDates[field.fieldName]
                        && !!queryTimezones[field.fieldName]
                        : true;
                default:
                    return true;
            }
        });
    }

    return (
        <Column spacingInset={'500'}>
            <Column>
                {
                    alertMessage && (
                        <Alert
                            type={alertMessage.type}
                            title={alertMessage.title}
                            onClose={() => {
                                setAlertMessage(undefined);
                            }}
                        >
                            {alertMessage.message}
                        </Alert>
                    )
                }
                <PageHeader headerText={configuration.resourceName() + 's'}/>
                {!!configuration.queryFields &&
                    <Expander open={queryBoxOpen} onChange={setQueryBoxOpen} type={'box'} title={'Search Problems'}>
                        <Column>
                            {
                                queryFields.map((field) => {
                                    return (
                                        <div key={field.fieldName} style={{paddingBottom: '10px'}}>
                                            {
                                                renderQueryField(field)
                                            }
                                        </div>
                                    );
                                })
                            }
                            <Row widths={['fill', 'fit']}>
                                <Button disabled={!isQueryReady(queryFields)} onClick={() => {
                                    setQuery(buildQuery());
                                    setQueryBoxOpen(false);
                                    setAlertMessage(undefined);
                                }}>Execute Query</Button>
                            </Row>
                        </Column>
                    </Expander>
                }
            </Column>
            <Box type={'outline'}>
                <Table
                    onSort={onSort}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    showDividers={true}
                    spacing={'small'}
                    fixHeaderRows={true}
                    headerRows={1}
                >
                    <TableActionBar alignmentHorizontal='justify'>
                        <Row spacing={'200'}>
                            <SearchField
                                onSubmit={() => {
                                }}
                                searchButton={true}
                                aria-label='InputLabel'
                                type='text'
                                width={300}
                                size='medium'
                                placeholder='Enter search query...'
                                value={inputValue}
                                onChange={setInputValue}
                            />
                            <Badge value={filteredResources ? filteredResources.length : 0} type='theme'/>
                        </Row>

                        <Row spacing={'400'}>
                            {checkedCount === 1 ? (
                                <TableActionBarOverlay
                                    onClose={onCloseActionBar}
                                    label={
                                        <span>
                                            <strong>{checkedCount}</strong>{' '}
                                            {`item${checkedCount === 1 ? '' : 's'} `}
                                            selected
                                        </span>
                                    }
                                    closeLabel='Cancel'
                                >
                                    <Button type='link'
                                        data-testid={'UpdateButton'}
                                        disabled={!configuration.navigateEditResource}
                                        onClick={() => configuration.navigateEditResource(selected[0])}>Update</Button>
                                    <Button type='link'
                                        data-testid={'ViewButton'}
                                        disabled={!configuration.navigateViewResource}
                                        onClick={() => configuration.navigateViewResource(selected[0])}>View</Button>
                                    <Button type='link'
                                        data-testid={'DeleteButton'}
                                        disabled={!configuration.deleteResource}
                                        onClick={() => setDeleteConfirmationModalOpen(true)}>Delete</Button>
                                    <Button type='link'
                                        data-testid={'ActionsButton'}
                                        disabled={!configuration.manageResourceActions}
                                        onClick={() => setResourceActionsModal(true)}>Quick Actions</Button>
                                    <Button type='link'
                                        data-testid={'ExportResource'}
                                        onClick={() => handleExport()}>Export Resource</Button>
                                </TableActionBarOverlay>
                            ) : null}
                            {checkedCount > 0 ? (
                                <TableActionBarOverlay
                                    onClose={onCloseActionBar}
                                    label={
                                        <span>
                                            <strong>{checkedCount}</strong>{' '}
                                            {`item${checkedCount === 1 ? '' : 's'} `}
                                            selected
                                        </span>
                                    }
                                    closeLabel='Cancel'
                                >
                                    <Button type='link' onClick={() => setDeleteConfirmationModalOpen(true)}>Delete</Button>
                                    <Button type='link'
                                        data-testid={'ActionsButton'}
                                        disabled={!configuration.manageResourceActions}
                                        onClick={() => setResourceActionsModal(true)}>Quick Actions</Button>
                                    <Button type='link'
                                        data-testid={'ExportResource'}
                                        onClick={() => handleExport()}>Export Resources</Button>
                                </TableActionBarOverlay>
                            ) : null}
                            <Button type='primary' size={'medium'}
                                data-testid={'CreateResourceButton'}
                                disabled={!configuration.navigateCreateResource}
                                onClick={() => configuration.navigateCreateResource()}>
                                Create
                            </Button>
                            <FileInput data-testid={'UploadResource'} type='single'
                                disabled={!configuration.navigateCreateResource}
                                uploadButtonLabel={'Upload Resources'} onFileAttached={onResourceUpload}>
                            </FileInput>
                            <Button type='secondary' size={'medium'}
                                data-testid={'RefreshButton'}
                                onClick={() => fetchResources()}>
                                <Icon tokens={refreshTokens}/>
                            </Button>
                        </Row>
                    </TableActionBar>

                    {
                        loadingResources && (
                            <TableRow>
                                <TableCell columnSpan={Object.keys(listResourceConfiguration).length + 1}>
                                    <LoadingMessage size='small' message={'Loading Table Items'}
                                        alignmentHorizontal='start'/>
                                </TableCell>
                            </TableRow>
                        )
                    }
                    {
                        !resourcesMap && (
                            <TableRow>
                                <TableCell
                                    columnSpan={Object.keys(listResourceConfiguration).length + 1}>{'Unable to Load Table Items'}</TableCell>
                            </TableRow>
                        )
                    }

                    {
                        !loadingResources && !alertMessage && filteredResources.length === 0 && (
                            <TableRow>
                                <TableCell columnSpan={Object.keys(listResourceConfiguration).length + 1}>No Results</TableCell>
                            </TableRow>
                        )
                    }

                    {hasQuery(queryFields) && !!filteredResources && !!filteredResources[0] && !loadingResources ?
                        <TableRow highlightOnHover={false}>
                            <TableCell key={'selectedHeader'}>
                                <Checkbox checked={checkedCount > 0} onChange={() => {
                                    checkedCount === 0 ?
                                        setSelected(filteredAndPaginatedResources) :
                                        setSelected([]);
                                }}
                                />
                            </TableCell>
                            {
                                Object.entries(listResourceConfiguration).map(([key, config], ii) => (
                                    <TableCell data-testid={'DataColumn-' + ii} key={key}
                                        sortColumn={config.sortable ? key : undefined} id={'header' + config}>
                                        {
                                            config.displayName ?? key
                                        }
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                        : null
                    }
                    {!loadingResources &&
                        hasQuery(queryFields)
                        && !!resourcesMap
                        && filteredAndPaginatedResources.map((resource, ii) => (
                            <TableRow data-testid={'DataRow-' + ii.toString()} key={JSON.stringify(resource)}
                                highlightOnHover={true}>
                                <TableCell data-testid={'SelectCell-' + ii.toString()}
                                    key={'select' + JSON.stringify(resource)}>
                                    <Row alignmentVertical='center'>
                                        <Checkbox checked={selected.includes(resource)}
                                            onChange={(isChecked) => isChecked ? setSelected(selected.concat([resource])) :
                                                setSelected(selected.filter(item => item !== resource))}></Checkbox>
                                    </Row>
                                </TableCell>
                                {
                                    Object.keys(listResourceConfiguration).map(attribute => {
                                        return (
                                            <TableCell key={attribute + JSON.stringify(resource)}>
                                                {displayAttribute(attribute, resource)}
                                            </TableCell>
                                        );
                                    }
                                    )
                                }
                            </TableRow>
                        ))
                    }
                </Table>
                <Row spacingInset={'400'} alignmentHorizontal={'justify'}>
                    <Select
                        value={itemsPerPage}
                        onChange={setItemsPerPage}
                        placeholder=''
                        width={100}
                    >
                        <SelectOption value={100} label='100'/>
                        <SelectOption value={25} label='25'/>
                        <SelectOption value={10} label='10'/>
                    </Select>
                    <Pagination
                        numberOfPages={numberOfPages}
                        onChange={setCurrentPage}
                        previousPageLabel={(page) => 'Prev'}
                        nextPageLabel={(page) => 'Next'}
                        currentPage={currentPage}
                    />
                </Row>
            </Box>


            {configuration.deleteResource ?
                <Modal
                    title='Resource Deletion Confirmation'
                    open={deleteConfirmationOpen}
                    onClose={() => setDeleteConfirmationModalOpen(false)}
                    scrollContainer='viewport'
                    closeLabel='Close'
                    aria-describedby='modal-description'
                >
                    <Column>
                        {selected.length > 1 ?
                            <Text type={'h100'} id='modal-description'>Are you sure you want to delete the
                                selected {selected.length} resources?</Text>
                            : <Text type={'h100'} id='modal-description'>Are you sure you want to delete the
                                selected resource?</Text>}
                        <Row>
                            <Button type={'secondary'} onClick={() => setDeleteConfirmationModalOpen(false)}>
                                No
                            </Button>
                            <Button data-testid={'ConfirmDelete'}
                                onClick={() => deleteResources(selected) && setDeleteConfirmationModalOpen(false)}
                                type={'primary'}>
                                Yes
                            </Button>
                        </Row>
                    </Column>
                </Modal>
                : <></>
            }

            {configuration.manageResourceActions ?
                <Modal
                    title='Manage Resources'
                    open={resourceActionsModal}
                    onClose={() => setResourceActionsModal(false)}
                    scrollContainer='viewport'
                    closeLabel='Close'
                    aria-describedby='manage-modal-description'
                >
                    <Column>
                        {configuration.manageResourceActions(webStageConfig, selectedRegion).map(action => {
                            return (
                                <Button disabled={!action.enabled(selected)} key={action.actionName}
                                    onClick={async () => {
                                        try {
                                            let success = await action.action(selected.map(resource => unmaskedResources[configuration.getId(resource)]));
                                            if (success) {
                                                setAlertMessage({
                                                    type: 'success',
                                                    message: action.actionName + ' successfully executed',
                                                    title: 'Action Completed'
                                                });
                                                setResourceActionsModal(false);
                                                fetchResources();
                                            } else {
                                                setAlertMessage({
                                                    type: 'error',
                                                    message: action.actionName = ' failed',
                                                    title: 'Error Executing Action'
                                                });
                                                setResourceActionsModal(false);
                                            }
                                        } catch (e: any) {
                                            setAlertMessage({
                                                type: 'error',
                                                message: e.message,
                                                title: 'Error Executing Action'
                                            });
                                            setResourceActionsModal(false);
                                        }
                                    }}>
                                    {action.actionName}
                                </Button>
                            );
                        })
                        }
                    </Column>
                </Modal>
                : <></>
            }

        </Column>
    );
};

export default TableResourceComponent;
