/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import React, {useMemo} from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';

import Dashboard from '../Dashboard/Dashboard';
import Home from '../Home/Home';
import {OverridesResourceConfiguration} from '../Resources/Configurations/impl/OverridesResourceConfiguration';
import {ProblemFinderResourceConfiguration} from '../Resources/Configurations/impl/ProblemFinderResourceConfiguration';
import {ProblemResourceConfig} from '../Resources/Configurations/impl/ProblemResourceConfiguration';
import {ProblemTypeResourceConfig} from '../Resources/Configurations/impl/ProblemTypeResourceConfiguration';
import {ReportConfigResourceConfig} from '../Resources/Configurations/impl/ReportConfigurationsResourceConfiguration';
import {WebhookResourceConfig} from '../Resources/Configurations/impl/WebhookResourceConfiguration';
import {WebhookSetResourceConfig} from '../Resources/Configurations/impl/WebhookSetResourceConfiguration';
import PutResourceComponent from '../Resources/General/PutResourceComponent';
import TableResourceComponent from '../Resources/General/TableResourceComponent';
import {ViewResourceComponent} from '../Resources/General/ViewResourceComponent';
import PutProblemFinder from '../Resources/ProblemFinders/RegisterProblemFinder';
import PutReportConfiguration from '../Resources/ReportConfigurations/CreateReportConfiguration';
import Page401 from './Page401';
import Page404 from './Page404';

export enum RouteName {
    HOME = '/',
    DASHBOARD = '/dashboard',
    PROBLEM_FINDERS = '/problemFinders',
    REPORT_CONFIGURATIONS = '/reportConfigurations',
    PROBLEM_TYPES = '/problemTypeDefinitions',
    OVERRIDES = '/overrides',
    WEBHOOKS = '/webhooks',
    WEBHOOK_SETS = '/webhookSets',
    PROBLEMS = '/problems',
}

const RoutingPage = () => {

    const navigate = useNavigate();
    const problemFinders = useMemo(() => new ProblemFinderResourceConfiguration(navigate), []);
    const reportConfigurations = useMemo(() => new ReportConfigResourceConfig(navigate), []);
    const problemTypes = useMemo(() => new ProblemTypeResourceConfig(navigate), []);
    const overrides = useMemo(() => new OverridesResourceConfiguration(navigate), []);
    const problems = useMemo(() => new ProblemResourceConfig(navigate), []);
    const webhooks = useMemo(() => new WebhookResourceConfig(navigate), []);
    const webhookSet = useMemo(() => new WebhookSetResourceConfig(navigate), []);
    const location = useLocation();

    return (
        <Routes>
            <Route path={RouteName.HOME} element={
                <Home key={location.pathname}/>
            }/>
            <Route path={RouteName.DASHBOARD} element={
                <Dashboard key={location.pathname}/>
            }/>
            <Route path='/401' element={
                <Page401 key={location.pathname}/>
            }/>
            <Route path='/404' element={
                <Page404 key={location.pathname}/>
            }/>
            <Route path={RouteName.PROBLEMS} element={
                <TableResourceComponent configuration={problems} key={location.pathname}/>
            }/>
            <Route path={RouteName.PROBLEMS + '/:id'} element={
                <ViewResourceComponent configuration={problems} key={location.pathname}/>
            }/>
            <Route path={RouteName.PROBLEM_FINDERS} element={
                <TableResourceComponent configuration={problemFinders} key={location.pathname}/>
            }/>
            <Route path={RouteName.PROBLEM_FINDERS + '/:id'} element={
                <ViewResourceComponent configuration={problemFinders} key={location.pathname}/>
            }/>
            <Route path={RouteName.PROBLEM_FINDERS + '/:problemFinderId/edit'} element={
                <PutProblemFinder inEditMode={true} key={location.pathname}/>
            }/>
            <Route path={RouteName.PROBLEM_FINDERS + '/create'} element={
                <PutProblemFinder inEditMode={false} key={location.pathname}/>
            }/>
            <Route path={RouteName.REPORT_CONFIGURATIONS} element={
                <TableResourceComponent configuration={reportConfigurations} key={location.pathname}/>
            }/>
            <Route path={RouteName.REPORT_CONFIGURATIONS + '/:id'} element={
                <ViewResourceComponent configuration={reportConfigurations} key={location.pathname}/>
            }/>
            <Route path={RouteName.REPORT_CONFIGURATIONS + '/:reportConfigurationId/edit'} element={
                <PutReportConfiguration inEditMode={true}/>
            }/>
            <Route path={RouteName.REPORT_CONFIGURATIONS + '/create'} element={
                <PutReportConfiguration inEditMode={false} key={location.pathname}/>
            }/>
            <Route path={RouteName.PROBLEM_TYPES} element={
                <TableResourceComponent configuration={problemTypes} key={location.pathname}/>
            }/>
            <Route path={RouteName.PROBLEM_TYPES + '/:id'} element={
                <ViewResourceComponent configuration={problemTypes} key={location.pathname}/>
            }/>
            <Route path={RouteName.PROBLEM_TYPES + '/:id/edit'} element={
                <PutResourceComponent inEditMode={true} configuration={problemTypes} key={location.pathname}/>
            }/>
            <Route path={RouteName.PROBLEM_TYPES + '/create'} element={
                <PutResourceComponent inEditMode={false} configuration={problemTypes} key={location.pathname}/>
            }/>
            <Route path={RouteName.OVERRIDES} element={
                <TableResourceComponent configuration={overrides} key={location.pathname}/>
            }/>
            <Route path={RouteName.OVERRIDES + '/:id'} element={
                <ViewResourceComponent configuration={overrides} key={location.pathname}/>
            }/>
            <Route path={RouteName.OVERRIDES + '/:id/edit'} element={
                <PutResourceComponent configuration={overrides} inEditMode={true} key={location.pathname}/>
            }/>
            <Route path={RouteName.OVERRIDES + '/create'} element={
                <PutResourceComponent configuration={overrides} inEditMode={false} key={location.pathname}/>
            }/>
            <Route path={RouteName.WEBHOOKS} element={
                <TableResourceComponent configuration={webhooks} key={location.pathname}/>
            }/>
            <Route path={RouteName.WEBHOOKS + '/create'} element={
                <PutResourceComponent inEditMode={false} configuration={webhooks} key={location.pathname}/>
            }/>
            <Route path={RouteName.WEBHOOKS + '/:id'} element={
                <ViewResourceComponent configuration={webhooks} key={location.pathname}/>
            }/>
            <Route path={RouteName.WEBHOOKS + '/:id/edit'} element={
                <PutResourceComponent inEditMode={true} configuration={webhooks} key={location.pathname}/>
            }/>

            <Route path={RouteName.WEBHOOK_SETS} element={
                <TableResourceComponent configuration={webhookSet} key={location.pathname}/>
            }/>
            <Route path={RouteName.WEBHOOK_SETS + '/create'} element={
                <PutResourceComponent inEditMode={false} configuration={webhookSet} key={location.pathname}/>
            }/>
            <Route path={RouteName.WEBHOOK_SETS + '/:id'} element={
                <ViewResourceComponent configuration={webhookSet} key={location.pathname}/>
            }/>
            <Route path={RouteName.WEBHOOK_SETS + '/:id/edit'} element={
                <PutResourceComponent inEditMode={true} configuration={webhookSet} key={location.pathname}/>
            }/>
            {/* Catch all for any unknown routes */}
            <Route path='*' element={
                <Page404 key={location.pathname}/>
            }/>
        </Routes>
    );
};

export default RoutingPage;
