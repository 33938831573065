/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import React from 'react';

import mothership from '../../../src/assets/images/mothership.svg';


/**
 * Home component.
 * @class for dashboard.
 */
export const Home = () => {
    return (
        <Row
            spacing='500'
            alignmentHorizontal='center'
            widths={[530, 'fill']}
            height={'60vh'}
            wrap='down'
            spacingInset='600 600 none 600'
        >
            <img src={mothership} alt='Image of Mothership'/>
            <Column spacing='500' maxWidth={450}>
                <Heading type='d100' level={1}>
                        Centralizing problems in Amazon Robotics
                </Heading>
                <Text>
                        ID4 is a scalable platform for detecting/reporting problems and performing/publishing diagnostics in the
                        Amazon Robotics and Advanced Technologies ecosystem
                </Text>
                <Row>
                    <Button href='/problemFinders/create'>
                            Onboarding
                    </Button>
                    <Button type={'secondary'}
                        href='https://w.amazon.com/bin/view/Amazon-Robotics/GSS/TOOLS/ID4/Integration/'>
                            Integrating
                    </Button>
                    <Button  type={'secondary'}
                        href='/dashboard'>
                            Monitoring
                    </Button>

                </Row>
            </Column>
        </Row>
    );
}
;

export default Home;
