/*
 * Copyright 2024 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {Clusivity} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/common';
import {ScopeFilter, ScopeFilterType} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/scheduler';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import Input from '@amzn/meridian/input';
import Row from '@amzn/meridian/row';
import Select, {SelectOption} from '@amzn/meridian/select';
import minusCircleTokens from '@amzn/meridian-tokens/base/icon/minus-circle';
import React from 'react';

import {useSearchSelect} from '../../../hooks/use-search-select';

export const ScopeFilterInput = ({setScopeFilterResult, idx, existingFilter, onRemoveClicked}: {
    setScopeFilterResult: (scopeFilter: ScopeFilter, idx: number) => void
    idx: number,
    existingFilter?: ScopeFilter,
    onRemoveClicked: (idx: number) => void
}) => {

    const [scopeFilterValue, setScopeFilterValue] = React.useState<string>(existingFilter.value);

    const [scopeFilterType, setScopeFilterType, ScopeFilterTypeSelect] = useSearchSelect({
        label: 'Filter type',
        placeholder: 'Select filter type',
        options: Object.entries(ScopeFilterType).map(([label, value]) => ({label, value})),
        isLoading: false,
        loadingMessage: 'Loading scope filter types...',
        width: '25%',
        selected: existingFilter.type
    });

    const [scopeFilterClusivity, setScopeFilterClusivity, ScopeFilterClusivitySelect] = useSearchSelect({
        label: 'Filter clusivity',
        placeholder: 'Select filter clusivity',
        options: Object.entries(Clusivity).map(([label, value]) => ({label, value})),
        isLoading: false,
        loadingMessage: 'Loading scope filter clusivities...',
        width: '25%',
        selected: existingFilter.clusivity
    });

    /**
     * Effect which will invoke a callback to set the problem filter result.
     */
    React.useEffect(() => {
        setScopeFilterResult({
            value: scopeFilterValue,
            type: scopeFilterType as ScopeFilterType,
            clusivity: scopeFilterClusivity as Clusivity
        }, idx);
    }, [scopeFilterValue, scopeFilterType, scopeFilterClusivity]);
    return (
        <Row alignmentHorizontal='start' alignmentVertical='bottom' width='100%'>
            <Input
                label='Filter value'
                value={scopeFilterValue}
                onChange={setScopeFilterValue}
                placeholder='Enter value'
                error={scopeFilterValue === undefined}
            />
            <div style={{
                marginBottom: '16px',
            }}>
                <Select
                    size='medium'
                    value={scopeFilterType}
                    label='Filter type'
                    error={scopeFilterType === undefined}
                    onChange={setScopeFilterType}
                >
                    {Object.entries(ScopeFilterType).map(([label, value]) => (
                        <SelectOption label={label} key={value} value={value}/>
                    ))}
                </Select>
            </div>
            <div style={{
                marginBottom: '16px',
            }}>
                <Select
                    size='medium'
                    value={scopeFilterClusivity}
                    label='Filter clusivity'
                    error={scopeFilterClusivity === undefined}
                    onChange={setScopeFilterClusivity}
                >
                    {Object.entries(Clusivity).map(([label, value]) => (
                        <SelectOption label={label} key={value} value={value}/>
                    ))}
                </Select>
            </div>
            <Button onClick={() => onRemoveClicked(idx)} type='secondary'>
                <Icon tokens={minusCircleTokens}>Remove</Icon>Remove
            </Button>
        </Row>
    );
};
