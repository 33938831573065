/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import SideMenu, {SideMenuLink} from '@amzn/meridian/side-menu';
import dashboardTokens from '@amzn/meridian-tokens/base/icon/dashboard';
import folderTokens from '@amzn/meridian-tokens/base/icon/folder';
import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import campaign from '../../../assets/images/campaign.svg';
import manageSearch from '../../../assets/images/manage_search.svg';
import override from '../../../assets/images/override.svg';
import radar from '../../../assets/images/radar.svg';
import webhook from '../../../assets/images/webhook.svg';
import webhookSet from '../../../assets/images/webhook_set.svg';
import {selectSideMenuOpen} from '../../../state/app/appSlice';
import {RouteName} from '../../Routing/RoutingPage';


const AppSideMenu = () => {

    const navigate = useNavigate();
    const sideMenuOpen = useSelector(selectSideMenuOpen);

    const onClickLink = useCallback(
        (href: RouteName) => {
            navigate({pathname: href});
        }, []);

    const windowContainsRoute = (route: string) => {
        return window.location.pathname === (route);
    };

    return (
        <SideMenu open={sideMenuOpen} type={'skinny'}>
            <SideMenuLink icon={dashboardTokens} href={RouteName.DASHBOARD}
                onClick={onClickLink} selected={windowContainsRoute(RouteName.DASHBOARD)}>
                Dashboard
            </SideMenuLink>
            <SideMenuLink
                icon={{
                    iconData: manageSearch,
                    iconWidth: 24,
                    iconHeight: 24,
                }}
                href={RouteName.PROBLEMS}
                onClick={onClickLink}
                selected={windowContainsRoute(RouteName.PROBLEMS)}>
                Problems
            </SideMenuLink>
            <SideMenuLink
                icon={{
                    iconData: radar,
                    iconWidth: 24,
                    iconHeight: 24,
                }}
                href={RouteName.PROBLEM_FINDERS}
                onClick={onClickLink}
                selected={windowContainsRoute(RouteName.PROBLEM_FINDERS)}>
                Detection
            </SideMenuLink>
            <SideMenuLink
                icon={{
                    iconData: campaign,
                    iconWidth: 24,
                    iconHeight: 24,
                }}
                href={RouteName.REPORT_CONFIGURATIONS}
                onClick={onClickLink}
                selected={windowContainsRoute(RouteName.REPORT_CONFIGURATIONS)}>
                Reporting
            </SideMenuLink>
            <SideMenuLink
                icon={folderTokens}
                href={RouteName.PROBLEM_TYPES}
                onClick={onClickLink}
                selected={windowContainsRoute(RouteName.PROBLEM_TYPES)}>
                Problem Types
            </SideMenuLink>

            <SideMenuLink
                icon={{
                    iconData: override,
                    iconWidth: 24,
                    iconHeight: 24,
                }}
                href={RouteName.OVERRIDES}
                onClick={onClickLink}
                selected={windowContainsRoute(RouteName.OVERRIDES)}>
                Overrides
            </SideMenuLink>
            <SideMenuLink href={RouteName.WEBHOOKS}
                icon={{
                    iconData: webhook,
                    iconWidth: 24,
                    iconHeight: 24,
                }}
                onClick={onClickLink}
                selected={windowContainsRoute(RouteName.WEBHOOKS)}>
                Webhooks
            </SideMenuLink>
            <SideMenuLink href={RouteName.WEBHOOK_SETS}
                icon={{
                    iconData: webhookSet,
                    iconWidth: 24,
                    iconHeight: 24,
                }}
                onClick={onClickLink}
                selected={windowContainsRoute(RouteName.WEBHOOK_SETS)}>
                Webhook Sets
            </SideMenuLink>


        </SideMenu>
    )
    ;
};

export default AppSideMenu;
