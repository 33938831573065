/*
 * Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Row from '@amzn/meridian/row';
import React from 'react';

import orb from '../../assets/images/orb.svg';

const Page401 = () => {
    return (

        <Row
            spacing='500'
            alignmentHorizontal='center'
            widths={[530, 'fill']}
            height={'60vh'}
            wrap='down'
            spacingInset='600 600 none 600'
        >
            <img src={orb} alt='Image of Orb'/>
            <Column spacing='500' maxWidth={450}>
                <Heading type='d100' level={1}>
                    You are not authorized to access this application.
                </Heading>
            </Column>
        </Row>
    );
};

export default Page401;
