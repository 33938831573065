/*
 * Copyright 2024 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {ProblemFinderOverride} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/override/types';
import {ScopeType} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/problem/types';
import {NavigateFunction} from 'react-router/dist/lib/hooks';
import {v4} from 'uuid';

import {WebStageConfig} from '../../../../config/id4-portal-config';
import {SupportedRegions} from '../../../../state/app/appSlice';
import {
    deleteProblemFinderOverride,
    getProblemFinderOverride,
    listProblemFinderOverrides,
    listProblemFinderRegistrations,
    putProblemFinderOverride
} from '../../../../utility/id4-mothership-client';
import {RouteName} from '../../../Routing/RoutingPage';
import {IDeleteResource} from '../IDeleteResource';
import {EditResourceComponentType, EditResourceConfiguration, IPutResource} from '../IPutResource';
import {ITableResource, ListResourceComponentType, ListResourceConfiguration} from '../ITableResource';
import {IViewResource} from '../IViewResource';


/**
 * The overrides' resource configuration.
 */
export class OverridesResourceConfiguration implements IPutResource<ProblemFinderOverride>, IViewResource<ProblemFinderOverride>, ITableResource<ProblemFinderOverride>, IDeleteResource<ProblemFinderOverride> {

    /**
     * Navigate function used to navigate the user to different pages.
     * @private
     */
    private readonly navigate: NavigateFunction;

    /**
     * MCM Link regex pattern.
     * @private
     */
    private mcmLinkRegexPattern: RegExp = /^https:\/\/mcm\.amazon\.com\/cms\/MCM-\d+$/;

    /**
     * Constructor for class.
     * @param navigate the navigate function.
     */
    constructor(navigate: NavigateFunction) {
        this.navigate = navigate;
    }

    async retrieveResources(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions): Promise<ProblemFinderOverride[]> {
        return await listProblemFinderOverrides(webStageConfig, selectedRegion);
    }

    retrieveResource(webStageConfig: WebStageConfig, region: SupportedRegions, resourceId: string): Promise<ProblemFinderOverride> {
        return getProblemFinderOverride(webStageConfig, region, {problemFinderOverrideId: resourceId});
    }

    async deleteResource(webStageConfig: WebStageConfig, region: SupportedRegions, resource: ProblemFinderOverride) {
        await deleteProblemFinderOverride(webStageConfig, region, {problemFinderOverrideId: resource.problemFinderOverrideId});
    }

    async putResource(webStageConfig: WebStageConfig, region: SupportedRegions, resource: ProblemFinderOverride): Promise<ProblemFinderOverride> {
        let transformedResource: ProblemFinderOverride = { ...resource };
        if (resource.externalLink) {
            transformedResource = this.transformExternalLink(resource);
        }

        return await putProblemFinderOverride(webStageConfig, region,
            {
                problemFinderOverrideId: transformedResource.problemFinderOverrideId,
                overrideDetails: {...transformedResource}
            }
        ).then(response => response.override);
    }

    navigateCreateResource(): void {
        this.navigate(`${RouteName.OVERRIDES}/create`);
    }

    navigateEditResource(resource: ProblemFinderOverride): void {
        this.navigate(`${RouteName.OVERRIDES}/${resource.problemFinderOverrideId}/edit`);
    }

    navigateViewResource(resource: ProblemFinderOverride): void {
        this.navigate(`${RouteName.OVERRIDES}/${resource.problemFinderOverrideId}`);
    }

    navigateTableView(): void {
        this.navigate(RouteName.OVERRIDES);
    }


    resourceName(): string {
        return 'Problem Finder Override';
    }

    async editResourceConfiguration(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions): Promise<Record<string, EditResourceConfiguration>> {
        let problemFinderRegistrations = await listProblemFinderRegistrations(webStageConfig, selectedRegion);
        return {
            // No custom identifiers here
            problemFinderOverrideId: {
                helperText: 'The Override ID',
                label: 'Override ID *',
                isIdentifier: true,
                editable: false
            },
            problemFinderId: {
                helperText: 'The problem finder this override is associated with.',
                label: 'Override Problem Finder ID *',
                componentType: EditResourceComponentType.SINGLE_SELECT_SEARCHABLE_LIST,
                acceptedValues: problemFinderRegistrations.map(v => {
                    return {label: v.problemFinderId, value: v.problemFinderId};
                }),
            },
            overrideScope: {
                helperText: 'The scope of the override',
                label: 'Override Scope *',
                editable: true,
                validationRegex: new RegExp('^(?:(^NA$)|(^EU$)|(^AP$)|(^[a-zA-Z0-9-*]+/[a-zA-Z0-9-*]+/[a-zA-Z0-9-*]+$)|(^\\w{4}$)|(^[A-Z]{3}\\d$)|(^K[A-Z]{3}$)|(^[A-Z]{3}\\d{2,3}$)|(^\\*$))$'),
                componentType: EditResourceComponentType.INPUT
            },
            overrideScopeType: {
                helperText: 'The scope type of the override',
                label: 'Override Scope Type *',
                acceptedValues: [ScopeType.KSK, ScopeType.REGION, ScopeType.BUILDING].map(v => {
                    return {label: v, value: v};
                }),
                componentType: EditResourceComponentType.SELECT
            },
            overrideKey: {
                helperText: 'The override key. Must match key used in Problem Finder.',
                label: 'Override Key *',
                componentType: EditResourceComponentType.INPUT
            },
            overrideValue: {
                helperText: 'The override value.',
                label: 'Override Value *',
                componentType: EditResourceComponentType.INPUT
            },
            overrideReason: {
                helperText: 'The reason for the override.',
                label: 'Override Reason',
                componentType: EditResourceComponentType.INPUT,
                requiredField: false
            },
            externalLink: {
                helperText: 'Link to an MCM. Must be a valid MCM link or ID (i.e. https://mcm.amazon.com/cms/MCM-000000000 or' +
                    ' MCM-000000000)',
                label: 'MCM Link',
                editable: true,
                componentType: EditResourceComponentType.INPUT,
                validationRegex: new RegExp(/^(?:https?:\/\/mcm\.amazon\.com\/cms\/|)MCM-\d+$/),
                requiredField: false,
            }
        };
    }

    async listResourceConfiguration(): Promise<Record<string, ListResourceConfiguration<ProblemFinderOverride>>> {
        return {
            problemFinderOverrideId: {
                displayName: 'Override ID',
                componentType: ListResourceComponentType.TEXT,
                onClick: id => this.navigate(RouteName.OVERRIDES + `/${id}`),
                sortable: true
            },
            problemFinderId: {
                displayName: 'Problem Finder ID',
                componentType: ListResourceComponentType.TEXT,
                sortable: true
            },
            overrideScope: {
                displayName: 'Scope',
                componentType: ListResourceComponentType.TEXT,
                sortable: true
            },
            overrideKey: {
                displayName: 'Override Key',
                componentType: ListResourceComponentType.TEXT,
                sortable: true
            },
            overrideValue: {
                displayName: 'Override Value',
                componentType: ListResourceComponentType.TEXT,
                sortable: true
            },
            overrideReason: {
                displayName: 'Override Reason',
                componentType: ListResourceComponentType.TEXT,
                sortable: false,
                maxValueLength: 100
            },
            externalLink: {
                displayName: 'MCM Link',
                componentType: ListResourceComponentType.TEXT,
                sortable: false,
                onClick: (link: string) => {
                    // Check if the link matches the pattern
                    if (link && this.mcmLinkRegexPattern.test(link)) {
                        window.open(link, '_blank', 'noopener,noreferrer');
                    } else {
                        return;
                    }
                }
            }
        };
    }

    initializeDefaultObject(): ProblemFinderOverride {
        return {
            problemFinderOverrideId: v4(),
            problemFinderId: undefined,
            overrideScope: undefined,
            overrideScopeType: undefined,
            overrideKey: undefined,
            overrideValue: undefined,
            updateTimeEpochMs: Date.now(),
            creationTimeEpochMs: Date.now(),
            overrideReason: undefined,
            externalLink: undefined
        };
    }


    getId(resource: ProblemFinderOverride): string {
        return resource.problemFinderOverrideId;
    }

    /**
     * Transforms the externalLink value from an ID to a full URL.
     * @param resource The resource object.
     * @returns {ProblemFinderOverride} with transformed externalLink value.
     */
    private transformExternalLink = (resource: ProblemFinderOverride): ProblemFinderOverride => {

        const transformedResource = { ...resource };

        transformedResource.externalLink = this.transformLinkId(resource.externalLink);

        return transformedResource;
    };

    /**
     * Transforms the input field value from an ID to a full URL.
     * @param {string} value The input field value.
     * @returns {string} The transformed value (full URL) or the original value if it's already a full URL.
     */
    private transformLinkId = (value: string): string => {
        const fullUrlPattern = this.mcmLinkRegexPattern;

        // If the value matches the full URL pattern, return as is
        if (fullUrlPattern.test(value)) {
            return value;
        }

        // Just ID format should be 'MCM-xxxxxx...'
        const idPattern = /^MCM-(\d+)$/;
        const match = value.match(idPattern);

        if (match) {
            const id = match[1];
            return `https://mcm.amazon.com/cms/MCM-${id}`;
        }

        // If the value doesn't match either pattern, return it as is to be handled gracefully
        return value;
    };


}
