/*
 * Copyright 2024 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {Clusivity} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/common';
import {ProblemFilter, ProblemFilterType} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/configuration/types';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import Input from '@amzn/meridian/input';
import Row from '@amzn/meridian/row';
import Select, {SelectOption} from '@amzn/meridian/select';
import minusCircleTokens from '@amzn/meridian-tokens/base/icon/minus-circle';
import React from 'react';

import {useSearchSelect} from '../../../hooks/use-search-select';


export const ProblemFilterInput = ({setProblemFilterResult, idx, existingFilter, onRemoveClicked}: {
    setProblemFilterResult: (problemFilter: ProblemFilter, idx: number) => void
    idx: number,
    existingFilter?: ProblemFilter,
    onRemoveClicked: (idx: number) => void
}) => {

    const [problemFilterValue, setProblemFilterValue] = React.useState<string>(existingFilter.value);

    const [problemFilterType, setProblemFilterType, ProblemFilterTypeSelect] = useSearchSelect({
        label: 'Filter type',
        placeholder: 'Select filter type',
        options: Object.entries(ProblemFilterType).map(([label, value]) => ({label, value})),
        isLoading: false,
        loadingMessage: 'Loading problem filter types...',
        width: '25%',
        selected: existingFilter.type
    });

    const [problemFilterClusivity, setProblemFilterClusivity, ProblemFilterClusivitySelect] = useSearchSelect({
        label: 'Filter clusivity',
        placeholder: 'Select filter clusivity',
        options: Object.entries(Clusivity).map(([label, value]) => ({label, value})),
        isLoading: false,
        loadingMessage: 'Loading problem filter clusivities...',
        width: '25%',
        selected: existingFilter.clusivity
    });

    /**
     * Effect which will invoke a callback to set the problem filter result.
     */
    React.useEffect(() => {
        setProblemFilterResult({
            value: problemFilterValue,
            type: problemFilterType as ProblemFilterType,
            clusivity: problemFilterClusivity as Clusivity
        }, idx);
    }, [problemFilterValue, problemFilterType, problemFilterClusivity]);

    return (
        <Row alignmentHorizontal='start' alignmentVertical='bottom' width='100%'>
            <Input
                label='Filter value'
                value={problemFilterValue}
                onChange={setProblemFilterValue}
                placeholder='Enter value'
                error={problemFilterValue === undefined}
            />
            <div style={{
                marginBottom: '16px',
            }}>
                <Select
                    size='medium'
                    value={problemFilterType}
                    label='Filter type'
                    error={problemFilterType === undefined}
                    onChange={setProblemFilterType}
                >
                    {Object.entries(ProblemFilterType).map(([label, value]) => (
                        <SelectOption label={label} key={value} value={value}/>
                    ))}
                </Select>
            </div>
            <div style={{
                marginBottom: '16px',
            }}>
                <Select
                    size='medium'
                    value={problemFilterClusivity}
                    label='Filter clusivity'
                    error={problemFilterClusivity === undefined}
                    onChange={setProblemFilterClusivity}
                >
                    {Object.entries(Clusivity).map(([label, value]) => (
                        <SelectOption label={label} key={value} value={value}/>
                    ))}
                </Select>
            </div>
            <Button onClick={() => onRemoveClicked(idx)} type='secondary'>
                <Icon tokens={minusCircleTokens}>Remove</Icon>Remove
            </Button>
        </Row>
    );
};
