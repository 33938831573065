/*
 * Copyright 2024 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {Problem, ProblemDetailsWithId, ProblemStatus} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/problem/types';
import {parseInt} from 'lodash';
import {NavigateFunction} from 'react-router/dist/lib/hooks';

import {WebStageConfig} from '../../../../config/id4-portal-config';
import {SupportedRegions} from '../../../../state/app/appSlice';
import {
    batchPutProblems,
    deleteProblem,
    getProblem,
    listProblemFinderRegistrations,
    listProblems
} from '../../../../utility/id4-mothership-client';
import {RouteName} from '../../../Routing/RoutingPage';
import {
    ITableResource,
    ListResourceComponentType,
    ListResourceConfiguration,
    ManageActionConfiguration,
    QueryFieldComponentType,
    QueryFieldConfiguration
} from '../ITableResource';
import {IViewResource} from '../IViewResource';

/**
 * Problem resource config.
 */
export class ProblemResourceConfig implements ITableResource<Problem>, IViewResource<Problem> {

    /**
     * Navigate function used to navigate the user to different pages.
     * @private
     */
    private readonly navigate: NavigateFunction;

    /**
     * Constructor for class.
     * @param navigate the navigate function.
     */
    constructor(navigate: NavigateFunction) {
        this.navigate = navigate;
    }

    async queryFields(webStageConfig: WebStageConfig, region: SupportedRegions): Promise<QueryFieldConfiguration[]> {
        let registrations = await listProblemFinderRegistrations(webStageConfig, region);
        return [
            {
                fieldName: 'problemFinderId',
                required: true,
                componentType: QueryFieldComponentType.SELECT,
                label: 'Problem Finder ID',
                valueRetrieve: () => {
                    return registrations.map(registration => registration.problemFinderId).sort();
                }
            },
            {
                fieldName: 'problemStatus',
                required: true,
                componentType: QueryFieldComponentType.SELECT,
                label: 'Problem Status',
                valueRetrieve: () => {
                    return ['OPEN', 'CLOSED', 'EXPIRED'];
                }
            },
            {
                fieldName: 'problemScope',
                required: false,
                componentType: QueryFieldComponentType.TEXT,
                label: 'Problem Scope'
            },
            {
                fieldName: 'identificationTimeEpochMs',
                required: true,
                componentType: QueryFieldComponentType.DATETIME,
                label: 'Start'
            },
            {
                fieldName: 'identificationEndTimeEpochMs',
                required: true,
                componentType: QueryFieldComponentType.DATETIME,
                label: 'End'
            }
        ];
    }


    putResource(webStageConfig: WebStageConfig, region: SupportedRegions, resource: Problem): Promise<Problem> {
        throw new Error('Method not implemented.');
    }

    initializeDefaultObject(): Problem {
        throw new Error('Method not implemented.');
    }

    async retrieveResources(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions, query: Record<string, string>): Promise<Problem[]> {
        const fourteenDaysInMs = 14 * 24 * 60 * 60 * 1000;
        if (query) {
            if (query['identificationTimeEpochMs'] && query['identificationEndTimeEpochMs']) {
                let start = parseInt(query['identificationTimeEpochMs']);
                let end = parseInt(query['identificationEndTimeEpochMs']);
                if (start > end) {
                    throw new Error('Start time must be before end time');
                }
                // Range between start and end time must not be greater than 14 days
                if (end - start > fourteenDaysInMs) {
                    throw new Error('Time range must be less than 14 days');
                }
            }
        }
        return await listProblems(webStageConfig, selectedRegion, query);
    }


    retrieveResource(webStageConfig: WebStageConfig, region: SupportedRegions, resourceId: string): Promise<Problem> {
        return getProblem(webStageConfig, region, {problemId: resourceId});
    }

    async deleteResource(webStageConfig: WebStageConfig, region: SupportedRegions, resource: Problem) {
        await deleteProblem(webStageConfig, region, {problemId: resource.problemId});
    }

    navigateViewResource(resource: Problem): void {
        this.navigate(`${RouteName.PROBLEMS}/${resource.problemId}`);
    }

    navigateTableView(): void {
        this.navigate(RouteName.PROBLEMS);
    }

    async listResourceConfiguration(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions): Promise<Record<string, ListResourceConfiguration<Problem>>> {
        return {
            problemId: {
                componentType: ListResourceComponentType.TEXT,
                displayName: 'Identifier',
                onClick: id => this.navigate(RouteName.PROBLEMS + `/${id}`),
                maxValueLength: 15
            },
            problemStatus: {
                componentType: ListResourceComponentType.TAG,
                displayName: 'Status',
                styleRetrieve: p => p.problemStatus === 'OPEN' ? 'success' : p.problemStatus === 'CLOSED' ? 'neutral' : 'error',
                sortable: true
            },
            problemFinderId: {
                componentType: ListResourceComponentType.TEXT,
                displayName: 'Problem Finder ID',
                onClick: (id) => this.navigate(RouteName.PROBLEM_FINDERS + `/${id}`),
                sortable: true
            },
            impactLevel: {
                componentType: ListResourceComponentType.TAG,
                displayName: 'Impact',
                sortable: true
            },
            problemScope: {
                componentType: ListResourceComponentType.TEXT,
                displayName: 'Scope',
                sortable: true,
                maxValueLength: 30
            },
            problemType: {
                componentType: ListResourceComponentType.TAG,
                displayName: 'Type',
                sortable: true
            },
            updateTimeEpochMs: {
                componentType: ListResourceComponentType.TIME,
                displayName: 'Updated',
                sortable: true
            },
        };
    }

    manageResourceActions(webStageConfig: WebStageConfig, region: SupportedRegions): ManageActionConfiguration<Problem>[] {
        return [
            {
                actionName: 'Close Problems',
                enabled: (resources: Problem[]) => resources.some(resource => resource.problemStatus === ProblemStatus.OPEN),
                action: async (resources: Problem[]) => {
                    let problemsToUpdate: Set<ProblemDetailsWithId> = new Set();
                    for (const resource of resources) {
                        let problem = {...resource};
                        problem.problemStatus = ProblemStatus.CLOSED;
                        problemsToUpdate.add(problem);
                    }
                    let response = await batchPutProblems(webStageConfig, region, {problems: [...problemsToUpdate]});
                    return !(!response || response.unprocessedProblems.length > 0);

                }
            }
        ];
    }


    resourceName(): string {
        return 'Problem';
    }

    getId(resource: Problem): string {
        return resource.problemId;
    }
}
