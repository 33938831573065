/*
 * Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import './app.scss';

import {LocalizationContextBuilder} from '@amzn/arb-tools';
import {ID4_CUSTOM_CLAIM_NAME, StageConfiguration} from '@amzn/id4-web-constants';
import AppLayout from '@amzn/meridian/app-layout';
import Theme from '@amzn/meridian/theme';
import theme from '@amzn/meridian-tokens/theme/definition';
import {MbmProvider} from '@amzn/react-arb-tools';
import {CognitoUserSession} from 'amazon-cognito-identity-js';
import {Amplify, Auth} from 'aws-amplify';
import {jwtDecode} from 'jwt-decode';
import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';

import arbManifest from '../build/translations/arbManifest.json';
import AppFooter from './components/AppLayout/AppFooter';
import AppMasthead from './components/AppLayout/Masthead/AppMasthead';
import AppSideMenu from './components/AppLayout/Masthead/AppSideMenu';
import {DEFAULT_LANGUAGE_VALUE} from './components/AppLayout/Masthead/LanguageMenu';
import LoadingPage from './components/Routing/LoadingPage';
import Page401 from './components/Routing/Page401';
import RoutingPage from './components/Routing/RoutingPage';
import {DEV_STAGE_NAME, getWebConfigForStage, WebStageConfig} from './config/id4-portal-config';
import configureStore from './state/configureStore';
import {getLocalStorageItem, LocalStorageKeys} from './utility/local-storage';

export const store = configureStore;

export type AppContextType = {
    stageConfiguration: StageConfiguration
    username: string
    customClaims: object
    webStageConfig: WebStageConfig
}

/**
 * Application React Context. Wraps all components with user/stage information required to access APIs.
 */
export const AppContext = React.createContext<AppContextType>(null);

// Define a localization context builder
export const localizationContextBuilder = new LocalizationContextBuilder()
    .withDefaultLocale(DEFAULT_LANGUAGE_VALUE);

const App = () => {

    const [userSession, setUserSession] = React.useState<CognitoUserSession>(null);

    const [stageConfiguration, setStageConfiguration] = React.useState<StageConfiguration>(null);

    const [webStageConfig, setWebStageConfig] = React.useState<WebStageConfig>(null);

    /**
     * Effect which will fetch the settings for Amplify and set the stage configuration state.
     */
    React.useEffect(() => {
        const fetchSettings = async () => {
            let settings: StageConfiguration;
            let webStageConfig: WebStageConfig;
            if (window.location.origin.includes('localhost')) {
                console.debug('Development environment detected.');
                settings = {
                    cognito: {
                        identityPoolId: process.env.DEV_IDENTITY_POOL_ID,
                        region: process.env.DEV_REGION,
                        userPoolId: process.env.DEV_USER_POOL_ID,
                        userPoolAppClientId: process.env.DEV_USER_POOL_APP_CLIENT_ID,
                        userPoolDomain: process.env.DEV_OAUTH_DOMAIN,
                        redirectUrl: `${process.env.HOSTNAME}:3000/`,
                    },
                    lambda: {
                        ctiLambdaArn: process.env.DEV_CTI_LAMBDA_ARN
                    }
                };

                // Set the dev web stage config if enabled
                if (process.env.DEV_MOTHERSHIP_API_ENDPOINT && process.env.DEV_REGION) {
                    console.log('Using developer API.');
                    webStageConfig = {
                        name: DEV_STAGE_NAME,
                        mothershipApiEndpoints: [{
                            name: `ID4MothershipAPIGateway-${DEV_STAGE_NAME}`,
                            endpoint: process.env.DEV_MOTHERSHIP_API_ENDPOINT,
                            region: process.env.DEV_REGION,
                            regionValue: 'Integ'
                        }]
                    };
                }
            } else {
                const result = await fetch(`/settings.json`, {credentials: 'include'});
                settings = await result.json();
            }

            // If the web stage config hasn't been set yet (which is either dev is not enabled or we're on a non-dev
            // platform, then set the stage config here
            if (!webStageConfig) {
                webStageConfig = getWebConfigForStage();
            }

            setWebStageConfig(webStageConfig);

            try {
                setStageConfiguration(settings);
            } catch (err) {
                console.error('Error setting stage configuration: ', err);
            }
        };

        fetchSettings()
            .catch(console.error);
    }, []);

    /**
     * Effect which will use the stage configuration to configure Amplify and then retrieve the current user session.
     */
    React.useEffect(() => {
        if (stageConfiguration && webStageConfig) {
            Amplify.configure({
                Auth: {
                    identityPoolId: stageConfiguration.cognito.identityPoolId,
                    region: stageConfiguration.cognito.region,
                    userPoolId: stageConfiguration.cognito.userPoolId,
                    userPoolWebClientId: stageConfiguration.cognito.userPoolAppClientId,
                    mandatorySignIn: true,
                    oauth: {
                        domain: stageConfiguration.cognito.userPoolDomain,
                        scope: ['openid'],
                        redirectSignIn: stageConfiguration.cognito.redirectUrl,
                        redirectSignOut: stageConfiguration.cognito.redirectUrl,
                        responseType: 'code'
                    },
                },
                API: {
                    endpoints: [
                        ...webStageConfig.mothershipApiEndpoints
                    ]
                }
            });

            const retrieveUserSession = async () => {
                try {
                    const session = await getCurrentSession();

                    setUserSession(session);
                } catch (err) {
                    console.error('Error getting user session: ', err);
                }
            };

            retrieveUserSession()
                .catch(console.error);
        }
    }, [stageConfiguration, webStageConfig]);

    /**
     * Gets the current user session, or, if none if found, log-in the user with the custom federated sign-in.
     *
     * @returns {Promise<CognitoUserSession>} Cognito user session
     */
    const getCurrentSession = async (): Promise<CognitoUserSession> => {
        return await Auth.currentSession()
            .then(session => {
                return session;
            })
            .catch(async error => {
                console.error(error);
                Auth.federatedSignIn({
                    customProvider: 'AmazonFederate',
                    // Include current URL in state to redirect to it after login
                    customState: window.location.pathname
                });
                return null;
            });
    };

    if (userSession !== null) {
        // Set the username in state
        const jwtToken = userSession.getIdToken().getJwtToken();
        const profile: any = jwtDecode(jwtToken);
        const username = profile['custom:alias'];
        // Custom claims come in a JSON string, the string map needs to be parsed out
        const customClaims = ID4_CUSTOM_CLAIM_NAME in profile ? JSON.parse(profile[ID4_CUSTOM_CLAIM_NAME]) : {};
        const isAuthorized = isUserAuthorized(profile);
        if (isAuthorized) {
            const selectedLanguageValue = getLocalStorageItem(LocalStorageKeys.ID4_LANGUAGE) ?? DEFAULT_LANGUAGE_VALUE;

            const mbmOption = {
                arbManifest,
                defaultLocalizationContext: localizationContextBuilder.withLocale(selectedLanguageValue).build(),
                resolveBundleUrl: (url: string | undefined) => url ? `${window.location.origin}/translations/${url}` : undefined,
            };

            return (
                <Provider store={store}>
                    <MbmProvider {...mbmOption}>
                        <AppContext.Provider value={{
                            stageConfiguration,
                            username,
                            customClaims,
                            webStageConfig
                        }}>
                            <Theme tokens={theme}>
                                <Router>
                                    <AppLayout
                                        headerComponent={AppMasthead}
                                        sidebarComponent={AppSideMenu}
                                        footerComponent={AppFooter}
                                        backgroundColor='alternateSecondary'
                                        alwaysShowScrollbar={false}
                                    >
                                        <AppMasthead/>
                                        <AppSideMenu/>
                                        <RoutingPage/>
                                        <AppFooter/>
                                    </AppLayout>
                                </Router>
                            </Theme>
                        </AppContext.Provider>
                    </MbmProvider>
                </Provider>
            );
        } else {
            return (
                <Theme tokens={theme}>
                    <Page401/>
                </Theme>
            );
        }
    } else {
        return (
            <Theme tokens={theme}>
                <LoadingPage/>
            </Theme>
        );
    }
};

const isUserAuthorized = (profile: any) => {
    return profile['cognito:groups'].includes('AuthorizedID4WebUsers');
};

export default App;
