/*
 * Copyright 2024 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {
    ReportConfigExecutionState,
    ReportConfiguration
} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/configuration/types';
import {NavigateFunction} from 'react-router/dist/lib/hooks';

import {WebStageConfig} from '../../../../config/id4-portal-config';
import {SupportedRegions} from '../../../../state/app/appSlice';
import {
    deleteReportConfiguration,
    getReportConfiguration,
    listReportConfigurations,
    putReportConfiguration
} from '../../../../utility/id4-mothership-client';
import {RouteName} from '../../../Routing/RoutingPage';
import {IDeleteResource} from '../IDeleteResource';
import {ITableResource, ListResourceComponentType, ListResourceConfiguration} from '../ITableResource';
import {IViewResource, ViewResourceComponentType, ViewResourceConfiguration} from '../IViewResource';


/**
 * Report config resource config.
 */
export class ReportConfigResourceConfig implements ITableResource<ReportConfiguration>, IViewResource<ReportConfiguration>, IDeleteResource<ReportConfiguration> {

    /**
     * Navigate function used to navigate the user to different pages.
     * @private
     */
    private readonly navigate: NavigateFunction;

    /**
     * Constructor for class.
     * @param navigate the navigate function.
     */
    constructor(navigate: NavigateFunction) {
        this.navigate = navigate;
    }


    async retrieveResources(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions): Promise<ReportConfiguration[]> {
        return await listReportConfigurations(webStageConfig, selectedRegion);
    }

    async retrieveResource(webStageConfig: WebStageConfig, region: SupportedRegions, resourceId: string): Promise<ReportConfiguration> {
        return await getReportConfiguration(webStageConfig, region, {reportConfigurationId: resourceId});
    }

    async deleteResource(webStageConfig: WebStageConfig, region: SupportedRegions, resource: ReportConfiguration): Promise<void> {
        await deleteReportConfiguration(webStageConfig, region, {reportConfigurationId: resource.reportConfigurationId});
    }

    navigateViewResource(resource: ReportConfiguration): void {
        this.navigate(`${RouteName.REPORT_CONFIGURATIONS}/${resource.reportConfigurationId}`);
    }

    navigateTableView(): void {
        this.navigate(RouteName.REPORT_CONFIGURATIONS);
    }

    navigateCreateResource(): void {
        this.navigate(RouteName.REPORT_CONFIGURATIONS + '/create');
    }

    navigateEditResource(resource: ReportConfiguration): void {
        this.navigate(RouteName.REPORT_CONFIGURATIONS + `/${resource.reportConfigurationId}/edit`);
    }

    async listResourceConfiguration(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions): Promise<Record<string, ListResourceConfiguration<ReportConfiguration>>> {
        return {
            reportConfigurationId: {
                displayName: 'Identifier',
                componentType: ListResourceComponentType.TEXT,
                onClick: id => this.navigate(RouteName.REPORT_CONFIGURATIONS + `/${id}`),
                sortable: true
            },
            enabled: {
                displayName: 'Enabled',
                componentType: ListResourceComponentType.TAG,
                styleRetrieve: value => {
                    return value.enabled ? 'success' : 'error';
                },
                sortable: true
            },
            reportConfigExecutionState: {
                displayName: 'State',
                componentType: ListResourceComponentType.TAG,
                styleRetrieve: resource => {
                    switch (resource.reportConfigExecutionState) {
                        case ReportConfigExecutionState.DISABLED_DUE_TO_ERROR:
                            return 'error';
                        case ReportConfigExecutionState.IDLE:
                            return 'theme';
                        case ReportConfigExecutionState.RUNNING:
                            return 'success';
                        default:
                            return 'theme';
                    }
                },
                sortable: true
            },
            scheduleCronExpression: {
                displayName: 'Schedule',
                componentType: ListResourceComponentType.TEXT,
                sortable: true
            },
            permissionsTeam: {
                displayName: 'Owner',
                componentType: ListResourceComponentType.TEXT,
                maxValueLength: 20,
                sortable: true
            },
            creationTimeEpochMs: {
                displayName: 'Created',
                componentType: ListResourceComponentType.TIME,
                sortable: true
            },
            updateTimeEpochMs: {
                displayName: 'Updated',
                componentType: ListResourceComponentType.TIME,
                sortable: true
            },
            lastExecutionTimeEpochMs: {
                displayName: 'Executed',
                componentType: ListResourceComponentType.TIME,
                sortable: true
            },
        };
    }

    async viewResourceConfiguration(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions, resource: ReportConfiguration): Promise<Record<string, ViewResourceConfiguration<ReportConfiguration>>> {
        return {
            reportConfigurationId: {
                displayName: 'Identifier',
                componentType: ViewResourceComponentType.TEXT,
            },
            enabled: {
                displayName: 'Enabled',
                componentType: ViewResourceComponentType.TEXT,
            },
            reportConfigExecutionState: {
                displayName: 'State',
                componentType: ViewResourceComponentType.TEXT
            },
            problemFinderIds: {
                displayName: 'Problem Finders',
                componentType: ViewResourceComponentType.LIST
            },
            scheduleCronExpression: {
                displayName: 'Schedule',
                componentType: ViewResourceComponentType.TEXT,
            },
            permissionsTeam: {
                displayName: 'Owner',
                componentType: ViewResourceComponentType.TEXT,
            },
            creationTimeEpochMs: {
                displayName: 'Created',
                componentType: ViewResourceComponentType.TIME,
            },
            updateTimeEpochMs: {
                displayName: 'Updated',
                componentType: ViewResourceComponentType.TIME,
            },
            lastExecutionTimeEpochMs: {
                displayName: 'Executed',
                componentType: ViewResourceComponentType.TIME,
            },
            problemFilters: {
                displayName: 'Problem Filters',
                componentType: ViewResourceComponentType.JSON
            },
        };
    }

    getId(resource: ReportConfiguration): string {
        return resource.reportConfigurationId;
    }


    resourceName(): string {
        return 'Report Configuration';
    }

    async putResource(webStageConfig: WebStageConfig, region: SupportedRegions, resource: ReportConfiguration): Promise<ReportConfiguration> {
        return await putReportConfiguration(webStageConfig, region,
            {
                reportConfigurationId: resource.reportConfigurationId,
                reportConfigurationDetails: {...resource}
            }
        ).then(response => response.reportConfiguration);
    }

    initializeDefaultObject(): ReportConfiguration {
        return {
            enabled: undefined,
            scheduleCronExpression: undefined,
            problemFinderIds: undefined,
            problemFilters: undefined,
            permissionsTeam: undefined,
            notifications: undefined,
            reportConfigurationId: undefined,
        };
    }


}
