/*
 * Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Row from '@amzn/meridian/row';
import React from 'react';

import mothership from '../../../src/assets/images/mothership.svg';
import LoadingMessage from '../utility-views/LoadingMessage';

const LoadingPage = () => {

    /**
     * Quotes from the movie Independence Day which ID4 is named after. Will randomly be shown on the loading screen.
     */
    const movieQuotes = [
        'Welcome to Earth!',
        'We will not go quietly into the night! We will not vanish without a fight! We’re going to live on! We’re going to survive! Today we celebrate our Independence Day!',
        'I could\'ve been at a barbecue!',
        'You really think you can fly that thing?',
        'Look, I really don\'t think they flew 90 billion light years to come down here and start a fight.'
    ];

    const getRandomQuote = movieQuotes[Math.floor(Math.random() * movieQuotes.length)];

    return (
        <Row
            spacing='500'
            alignmentHorizontal='center'
            widths={[530, 'fill']}
            height={'60vh'}
            wrap='down'
            spacingInset='600 600 none 600'
        >
            <img src={mothership} alt='Image of Mothership'/>
            <Column spacing='500' maxWidth={450}>
                <LoadingMessage size='large' spacingInset='300' message={`Loading ID4 web portal... ${getRandomQuote}`}/>
            </Column>
        </Row>
    );
};

export default LoadingPage;
